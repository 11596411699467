/* eslint-disable */

import { Checkbox, Modal, RadioChangeEvent } from "antd";
import WhatsappConf from "../../../../../components/src/static/whatsapp_conf.svg";
import WhatsappConfNoAds from "../../../../../components/src/static/whatsapp_conf_noads.svg";
import SMSConf from "../../../../../components/src/static/SMS_conf.svg";
import NoConf from "../../../../../components/src/static/no_confirmation.svg";
import WeatherInfo from "../../../../../components/src/static/weather_yes.svg";
import GarantiaResv from "../../../../../components/src/static/garantia resv_yes.svg";
import sameShiftImg from "../utils/example-images/same-shift.png";
import { Button, Input, Radio, Space } from "antd";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FC, useEffect, useState } from "react";
import { SchedulerComp } from "src/components/inputs/scheduler";
import "../styles.css";
import TextArea from "antd/es/input/TextArea";
import notification_whatsapp from "../utils/avatars-svg/notification-whatsapp.svg";
import notification_mail from "../utils/avatars-svg/notification-mail.svg";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { marginLeft } from "@doar/shared";
import { useTranslation } from "react-i18next";

interface IProps {
  prefill?: any;
  handleNext?: any;
  handleBack?: any;
  handleChange?: any;
}

export const RestReservation0: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState([""]);
  const { t } = useTranslation();

  // const onChangeRadio = (e: RadioChangeEvent) => {
  //   handleChange("notifications_channel", e.target.value);
  // };
  const onChange = (checkedValues: CheckboxValueType[]) => {
    handleChange("notifications_channel", checkedValues);
  };
  useEffect(() => {
    if (prefill?.notifications_channel) {
      setValue(prefill?.notifications_channel);
    } else setValue(["whatsapp"]);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿Cómo quieres recibir las notificaciones de reserva?</h2>
      <div style={{ marginTop: "50px", display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
        <Checkbox.Group
          className="checkBoxGroup"
          style={{ marginTop: "50px", display: "flex", alignItems: "center" }}
          //  options={plainOptions}
          value={value}
          onChange={onChange}
        >
          <Space direction="vertical">
            <Checkbox className="langItemCheck" value="whatsapp">
              Whatsapp
            </Checkbox>
            {
              (value[0] === "whatsapp" || value[1] === "whatsapp") && (
                <div>
                  <PhoneInput
                    className="phone"
                    style={{ fontSize: "25px" }}
                    placeholder="Ingrese su número de teléfono"
                    defaultCountry="ES"
                    value={prefill?.notifications_channel_whatsapp_value}
                    onChange={(e: any) => handleChange("notifications_channel_whatsapp_value", e)}
                  />
                </div>
              )
              // <Input style={{ width: "100%", marginLeft: 10 }} placeholder="¿A que número?" value={prefill?.notifications_channel_value} onChange={(e: any) => handleChange("notifications_channel_value", e.target.value)} />
            }

            <Checkbox className="langItemCheck" value="mail">
              Correo electrónico
            </Checkbox>
            {(value[0] === "mail" || value[1] === "mail") && (
              <Input type="email" style={{ width: "100%", marginLeft: 10 }} placeholder="¿A que correo?" value={prefill?.notifications_channel_mail_value} onChange={(e: any) => handleChange("notifications_channel_mail_value", e.target.value)} />
            )}
          </Space>
        </Checkbox.Group>
        {(prefill?.notifications_channel[0] === "whatsapp" || prefill?.notifications_channel.length > 1) && <img src={notification_whatsapp} />}
        {(prefill?.notifications_channel[0] === "mail" || prefill?.notifications_channel.length > 1) && <img src={notification_mail} />}
      </div>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.notifications_channel} onClick={() => handleNext("rest-reservation-0", "rest-reservation-1")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestReservation1: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("restaurant_zones", e.target.value);
    if (e.target.value !== "yes") {
      // setTimeout(() => {
      //   handleNext("rest-reservation-1", "rest-reservation-2");
      // }, 700);
    }
  };
  useEffect(() => {
    setValue(prefill?.restaurant_zones);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿Ofrecéis a los clientes la opción de escoger la zona del restaurante?</h2>
      <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
        <Space direction="vertical">
          <Radio value="no">No</Radio>
          <Radio value="yes">Sí</Radio>
          {value === "yes" && prefill?.booking_manager !== "restoo" && (
            <Input style={{ width: "100%", marginLeft: 10 }} placeholder="ej. terraza, barra" value={prefill?.other_restaurant_zones} onChange={(e: any) => handleChange("other_restaurant_zones", e.target.value)} />
          )}
        </Space>
      </Radio.Group>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.restaurant_zones || (prefill?.restaurant_zones === "yes" && prefill?.booking_manager !== "restoo" && !prefill?.other_restaurant_zones)} onClick={() => handleNext("rest-reservation-1", null)}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const RestReservation2: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("same_shift", e.target.value);
    // setTimeout(() => {
    //   handleNext("rest-reservation-2", null);
    // }, 700);
  };
  useEffect(() => {
    setValue(prefill?.same_shift);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">Si quieren la reserva para el turno actual, ya empezado, qué quieres que haga el asistente?</h2>
      <div style={{ display: "flex", marginTop: "50px" }}>
        <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
          <Space direction="vertical">
            <Radio value="allowed">Se aceptarán reservas</Radio>
            <Radio value="transfer_restaurant">Se pasará la llamada al restaurante</Radio>
            <Radio value="not_allowed_go_to_restaurant">Se le indicará al cliente que vaya presencialmente.</Radio>
            <Radio value="not_allowed_custom">No se aceptarán reservas y dirá un mensaje personalizado</Radio>
            {value === "not_allowed_custom" && (
              <Input style={{ width: "100%", marginLeft: 10 }} placeholder="Indica lo que quieras que diga el asistente" value={prefill?.same_shift_custom} onChange={(e: any) => handleChange("same_shift_custom", e.target.value)} />
            )}
          </Space>
        </Radio.Group>
        {prefill?.same_shift === "not_allowed_go_to_restaurant" && <img src={sameShiftImg} alt="go to restaurant image" />}
      </div>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.same_shift || (prefill?.same_shift === "not_allowed_custom" && !prefill?.same_shift_custom)} onClick={() => handleNext("rest-reservation-2", null)}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestReservation3: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const { t } = useTranslation();

  let scheduleModelR: any = {
    days: {
      Monday: { shifts: [] },
      Tuesday: { shifts: [] },
      Wednesday: { shifts: [] },
      Thursday: { shifts: [] },
      Friday: { shifts: [] },
      Saturday: { shifts: [] },
      Sunday: { shifts: [] },
    },
    exceptions: [],
    always_enabled: {
      value: false,
    },
  };
  const [schedule, setSchedule] = useState(scheduleModelR);

  useEffect(() => {
    setSchedule(prefill?.current_shift_policy_schedule);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">Indicar el horario de los turnos</h2>

      <SchedulerComp
        prefill={schedule}
        submit={(e: any) => {
          handleChange("current_shift_policy_schedule", e);
          console.log("SCHEDULE CHANGE", e);
        }}
        isRestaurant
      />

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button disabled={JSON.stringify(schedule) == JSON.stringify(scheduleModelR) || !prefill?.current_shift_policy_schedule} type="primary" onClick={() => handleNext("rest-reservation-3", null)}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestReservation4: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (val: any) => {
    handleChange("no_availability_transfer", val.target.value);
  };

  const [items, setItems] = useState([
    // { id: 1, name: "Proponer otra hora con 1h de diferencia, antes o despues", checked: false },
    { id: 1, name: "Proponer otra hora dentro del mismo turno", checked: false },
    { id: 2, name: "Proponer otra hora dentro del mismo dia", checked: false },
    { id: 3, name: "Proponer otro dia, en la misma semana, en el mismo turno", checked: false },
    { id: 4, name: "Proponer otro dia, en la misma semana", checked: false },
    { id: 5, name: "Venta cruzada con otro restaurante", checked: false },
    // { id: 6, name: "Pasar la llamada al restaurante", checked: false },
  ]);

  const handleCheckboxChange = (itemId: any) => {
    const updatedItems = items.map((item) => {
      if (item.id === itemId) {
        return { ...item, checked: !item.checked };
      } else {
        return item;
      }
    });
    setItems(updatedItems);
    console.log("ITEMS", updatedItems);
    handleChange("reservation_policy", updatedItems);
  };

  const handleDragStart = (e: any, id: any) => {
    e.dataTransfer.setData("text/plain", id);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (e: any, targetIndex: any) => {
    const id = parseInt(e.dataTransfer.getData("text"));
    const draggedItemIndex = items.findIndex((item) => item.id === id);
    const updatedItems = [...items];
    const [draggedItem] = updatedItems.splice(draggedItemIndex, 1);
    updatedItems.splice(targetIndex, 0, draggedItem);
    setItems(updatedItems);
    handleChange("reservation_policy", updatedItems);
  };

  useEffect(() => {
    if (prefill?.reservation_policy) {
      setItems(prefill?.reservation_policy);
    }
    if (prefill?.no_availability_transfer) {
      setValue(prefill?.no_availability_transfer);
    }
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">En el caso de que la hora exacta que quiere el cliente no tenga disponibilidad, elige las distintas opciones que ofrecerá el asistente</h2>
      <p className="pUnderTitle">Puedes seleccionar varias y modificar el orden de prioridad arrastrando las propuestas hasta la posición deseada. Sólo serán tomadas en cuenta las propuestas que estén seleccionadas. </p>
      {/* <Checkbox.Group className="checkBoxGroup proposalPolicies" onChange={onChangeCheckbox} value={value}> */}
      <Space style={{ margin: "100px 50px 50px 50px" }} direction="vertical">
        <ul>
          {items.map((item, index) => (
            <li style={{ marginBottom: "15px" }} key={item.id} draggable={true} onDragStart={(e) => handleDragStart(e, item.id)} onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, index)}>
              {index + 1}
              {" - "}
              <Checkbox checked={item.checked} onChange={() => handleCheckboxChange(item.id)}>
                <div style={{ fontWeight: 400, fontSize: "18px" }}>{item.name}</div>
              </Checkbox>
            </li>
          ))}
        </ul>
      </Space>

      {prefill?.when_handover !== "never" && (
        <div>
          <h4 style={{ textAlign: "center", margin: "10px" }}>En caso de que no sea posible ninguna de las opciones anteriores, ¿Quieres que se transfiera la llamada al restaurante?</h4>
          <Radio.Group className="radioGroupStyle" style={{ margin: "15px 50px" }} onChange={onChangeRadio} value={value}>
            <Space direction="vertical">
              <Radio value="no">No</Radio>
              {value === "no" && (
                <TextArea
                  style={{ width: "100%", marginLeft: 10 }}
                  placeholder="OPCIONAL: Escribe un mensaje personalizado para decirle al cliente"
                  value={prefill?.no_availability_no_transfer_message}
                  onChange={(e: any) => handleChange("no_availability_no_transfer_message", e.target.value)}
                />
              )}
              <Radio value="open">Sí, en el horario de apertura del restaurante</Radio>
              {value === "open" && (
                <TextArea
                  style={{ width: "100%", marginLeft: 10 }}
                  placeholder="OPCIONAL: Escribe un mensaje personalizado para decirle al cliente antes de transferir la llamada"
                  value={prefill?.no_availability_transfer_message}
                  onChange={(e: any) => handleChange("no_availability_transfer_message", e.target.value)}
                />
              )}
              {prefill?.when_handover === "scheduled" && <Radio value="handover">Sí, en las franjas horarias definidas anteriormente para pasar las llamadas al restaurante</Radio>}

              {value === "handover" && (
                <TextArea
                  style={{ width: "100%", marginLeft: 10 }}
                  placeholder="OPCIONAL: Escribe un mensaje personalizado para decirle al cliente antes de transferir la llamada"
                  value={prefill?.no_availability_transfer_message}
                  onChange={(e: any) => handleChange("no_availability_transfer_message", e.target.value)}
                />
              )}
              <Radio value="custom">Sí, en las franjas personalizadas</Radio>

              {value === "custom" && (
                <TextArea
                  style={{ width: "100%", marginLeft: 10 }}
                  placeholder="OPCIONAL: Escribe un mensaje personalizado para decirle al cliente antes de transferir la llamada"
                  value={prefill?.no_availability_transfer_message}
                  onChange={(e: any) => handleChange("no_availability_transfer_message", e.target.value)}
                />
              )}
            </Space>
          </Radio.Group>
          {value === "custom" && (
            <div>
              <h4 style={{ textAlign: "center", margin: " 30px" }}>Por favor indica el horario en el que se podrá pasar la llamada al restaurante</h4>
              <SchedulerComp
                prefill={prefill?.no_availability_transfer_schedule}
                submit={(e: any) => {
                  handleChange("no_availability_transfer_schedule", e);
                  console.log("SCHEDULE CHANGE", e);
                }}
                isRestaurant
              />
            </div>
          )}
          {/* </Checkbox.Group> */}
        </div>
      )}

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.reservation_policy} onClick={() => handleNext("rest-reservation-4", "rest-reservation-5")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const RestReservation5: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("shift_duration_policy", e.target.value);
  };
  useEffect(() => {
    setValue(prefill?.shift_duration_policy);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿En qué caso quieres indicar el tiempo máximo que pueden permanecer en la mesa?</h2>
      <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
        <Space direction="vertical">
          <Radio value="never"> Nunca</Radio>
          <Radio value="always">Siempre</Radio>
          {value === "always" && (
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <Input
                type="number"
                style={{ width: "50%", marginLeft: 10, marginRight: "10px" }}
                placeholder="Indica el tiempo máximo en minutos"
                value={prefill?.reservation_duration}
                onChange={(e: any) => handleChange("reservation_duration", e.target.value)}
              />{" "}
              <h6>minutos</h6>
            </div>
          )}
          {(prefill?.booking_manager === "cover" || prefill?.booking_manager === "restoo") && <Radio value="same_as_booking_manager">Tal como está en mi libro de reservas</Radio>}
          <Radio value="custom">Otro</Radio>
          {value === "custom" && (
            <TextArea rows={5} style={{ width: "400px", marginLeft: 10 }} placeholder="Dinos en qué casos indicarlo." value={prefill?.shift_duration_policy_custom} onChange={(e: any) => handleChange("shift_duration_policy_custom", e.target.value)} />
          )}
        </Space>
      </Radio.Group>
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.shift_duration_policy || (prefill?.shift_duration_policy === "custom" && !prefill?.shift_duration_policy_custom)} onClick={() => handleNext("rest-reservation-5", "rest-reservation-6")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestReservation6: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("say_reservation_hold_policy", e.target.value);
    // if (e.target.value !== "yes") {
    //   setTimeout(() => {
    //     handleNext("rest-reservation-6", "rest-reservation-7");
    //   }, 700);
    // }
  };
  useEffect(() => {
    setValue(prefill?.say_reservation_hold_policy);
  }, [prefill]);

  return (
    <div className="contentContainer">
      <h2 className="businessH2">
        {t("ask_warranty")}
        {/* ¿Quieres indicar el tiempo de garantia de reserva? */}
      </h2>
      <div className="RadioAndImg">
        <Radio.Group className="radioGroupStyle tiempoGarantia" onChange={onChangeRadio} value={value}>
          <Space direction="vertical" style={{ gap: "0" }}>
            <Radio value="no"> {t("no")}</Radio>
            <div className="inputMinGarantiaCont">
              <Radio value="yes"> {t("yes")}</Radio>
              {value === "yes" && (
                <Input
                  type="number"
                  className="inputMinGarantia"
                  style={{ width: "290px", marginLeft: "0px", padding: "10px", overflowY: "hidden", zIndex: "2" }}
                  placeholder={t("ask_warranty_time")}
                  value={prefill?.reservation_hold_policy}
                  onChange={(e: any) => handleChange("reservation_hold_policy", e.target.value)}
                />
              )}
            </div>
          </Space>
        </Radio.Group>
        <div className="imgContainer">
          <img className="GarantiaResvImg" src={GarantiaResv} alt="Garantia example" />
        </div>
      </div>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.say_reservation_hold_policy || (prefill?.say_reservation_hold_policy === "yes" && !prefill?.reservation_hold_policy)} onClick={() => handleNext("rest-reservation-6", "rest-reservation-9")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestReservation7: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("time_limitation", e.target.value);
    // if (e.target.value !== "yes") {
    //   setTimeout(() => {
    //     handleNext("rest-reservation-7", "rest-reservation-8");
    //   }, 700);
    // }
  };
  useEffect(() => {
    setValue(prefill?.time_limitation);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿En tu libro de reservas teneis alguna limitación de tiempo para reservar antes o durante el turno?</h2>
      <p className="pUnderTitle">P. ej. Las reservas se bloquean 1 hora antes del comienzo del turno</p>
      <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
        <Space direction="vertical">
          <Radio value="no">No</Radio>
          <Radio value="yes">Sí</Radio>
          {value === "yes" && <Input style={{ width: "300px", marginLeft: 10 }} placeholder="p. ej. Hasta 2 horas antes" value={prefill?.time_limitation_value} onChange={(e: any) => handleChange("time_limitation_value", e.target.value)} />}
        </Space>
      </Radio.Group>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.time_limitation || (prefill?.time_limitation === "yes" && !prefill?.time_limitation_value)} onClick={() => handleNext("rest-reservation-7", "rest-reservation-8")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestReservation8: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("say_weather", e.target.value);
    // setTimeout(() => {
    //   handleNext("rest-reservation-8", "rest-reservation-9");
    // }, 700);
  };
  useEffect(() => {
    setValue(prefill?.say_weather);
  }, [prefill]);

  return (
    <div>
      <div>
        <h2 className="businessH2">¿Quieres que el asistente consulte el clima para la hora que quiere reservar?</h2>
      </div>
      <div>
        <div className="RadioAndImg">
          <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
            <Space direction="vertical">
              <Radio value="no">No</Radio>
              <Radio value="yes">Sí</Radio>
            </Space>
          </Radio.Group>
          <div>
            <img className="tratoImage" src={WeatherInfo} alt="Weather info example" />
          </div>
        </div>
      </div>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.say_weather} onClick={() => handleNext("rest-reservation-8", "rest-reservation-9")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestReservation9: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const [valueDataCustom, setValueDataCustom] = useState("");
  const [valueData, setValueData] = useState([]);
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("extra_questions", e.target.value);
    // if (e.target.value !== "yes") {
    //   setTimeout(() => {
    //     handleNext("rest-reservation-9", "rest-reservation-10");
    //   }, 700);
    // }
  };
  const onChangeCheckbox = (e: any) => {
    handleChange("extra_questions_data", e);
    // if (e.target.value !== "yes") {
    //   setTimeout(() => {
    //     handleNext("rest-reservation-9", "rest-reservation-10");
    //   }, 700);
    // }
  };
  useEffect(() => {
    setValue(prefill?.extra_questions_data?.length > 0 ? "yes" : "no");
    setValueData(prefill?.extra_questions_data);
    setValueDataCustom(prefill?.extra_question_custom);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">
        {t("extra_q")}

        {/* ¿Quieres que el asistente pregunte o diga algo mas? */}
      </h2>
      <p className="pUnderTitle">
        {t("extra_q2")}
        {/* Si no estás seguro podrás indicarlo más adelante */}
      </p>
      <Radio.Group className="removeMargin" style={{ display: "flex" }} onChange={onChangeRadio} value={value}>
        <Space direction="vertical">
          <Checkbox.Group className="proposalPolicies" onChange={onChangeCheckbox} value={valueData}>
            <Space direction="vertical">
              <Checkbox value="1" style={{ padding: "8px 0" }}>
                <div className="checkboxText">
                  {t("alergies")}

                  {/* ¿Algún comentario adicional como alergias, intolerancias o peticiones especiales que quieras indicar? */}
                </div>
              </Checkbox>
              <Checkbox value="2" style={{ padding: "8px 0" }}>
                <div className="checkboxText">
                  {t("extra_highchair")}

                  {/* En caso de venir con niños y necesitar tronas, por favor especifique cuántas necesitas. */}
                </div>
              </Checkbox>
              <Checkbox value="3" style={{ padding: "8px 0" }}>
                <div className="checkboxText">
                  {t("ask_how_know_us")}

                  {/* ¿Cómo nos ha conocido? */}
                </div>
              </Checkbox>
              <Checkbox value="4" style={{ padding: "8px 0" }}>
                <div className="checkboxText">
                  {t("ask_disca")}

                  {/* ¿Viene alguna persona con movilidad reducida? */}
                </div>
              </Checkbox>
            </Space>
          </Checkbox.Group>

          <TextArea
            rows={5}
            className="textAreaPropPolicies"
            style={{ width: "700px", height: "100px" }}
            placeholder={t("extra_placeholder")}
            // "Indica que más quieres que diga el asistente. Puedes agregar varias frases o preguntas."
            value={valueDataCustom}
            onChange={(e: any) => handleChange("extra_question_custom", e.target.value)}
          />
        </Space>
        {/* <Space direction="vertical">
          <Radio style={{ marginBottom: "20px", fontSize: "18px", fontWeight: 400 }} value="no">
            No
          </Radio>
          <Radio style={{ marginBottom: "20px", fontSize: "18px", fontWeight: 400 }} value="yes">
            Sí
          </Radio>
        </Space>
        {value === "yes" && (
        )} */}
      </Radio.Group>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button
            type="primary"
            // disabled={!prefill?.extra_questions || (prefill?.extra_questions === "yes" && !prefill?.extra_questions_data?.length && !prefill?.extra_question_custom)}
            onClick={() => handleNext("rest-reservation-9", "rest-reservation-10")}
          >
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const RestReservation10: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("send_confirmation", e.target.value);
    // setTimeout(() => {
    //   handleNext("rest-reservation-10", "rest-reservation-11");
    // }, 700);
  };
  useEffect(() => {
    setValue(prefill?.send_confirmation);
  }, [prefill]);

  return (
    <div className="contentContainer">
      <div>
        <h2 className="businessH2">
        {t("ask_conf_notif")}
        {/* ¿Quieres enviarle una confirmación de la reserva al cliente? */}
        </h2>
      </div>
      <div>
        <div className="RadioAndImg">
          <Radio.Group className="radioGroupStyle confirmationBlock" onChange={onChangeRadio} value={value}>
            <Space direction="vertical">
              <Radio className="sendConfirmation" value="whatsapp-free">
                      {t("yes_mkt")}

                {/* Sí, con WhatsApp con publicidad de bookline */}
              </Radio>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontSize: "14px", fontStyle: "italic", paddingLeft: "30px" }}>
                        {t("free")}

                {/* gratuito */}
                </p>
              </div>
              <Radio className="sendConfirmation" value="whatsapp-paid">
                      {t("yes_paid")}

                {/* Sí, con WhatsApp sin publicidad */}
              </Radio>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontSize: "14px", fontStyle: "italic", paddingLeft: "30px" }}>10 c/msg</p>
              </div>
              
              <Radio className="sendConfirmation" value="sms">
                      {t("yes_sms")}

                {/* Sí, con SMS */}
              </Radio>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontSize: "14px", fontStyle: "italic", paddingLeft: "30px" }}>10 c/msg</p>
              </div>
              <Radio className="sendConfirmation" value="no">
                      {t("dont_want")}

                {/* No quiero */}
              </Radio>
            </Space>
          </Radio.Group>
          <div className="displayNone">
            <img
              className="Whatsconf1"
              src={
                prefill?.send_confirmation === "no"
                  ? NoConf
                  : prefill?.send_confirmation === "sms"
                  ? SMSConf
                  : prefill?.send_confirmation === "whatsapp-paid"
                  ? WhatsappConfNoAds
                  : prefill?.send_confirmation === "whatsapp-free"
                  ? WhatsappConf
                  : WhatsappConf
              }
              alt="ConfirmationWhats"
            />
          </div>
        </div>
      </div>
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.send_confirmation} onClick={() => handleNext("rest-reservation-10", "rest-reservation-11")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const RestReservation11: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("final_comment", e.target.value);
  };
  useEffect(() => {
    setValue(prefill?.final_comment);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">
              {t("anything_else")}

       {/* ¿Algo más que nos quieras indicar */}
       </h2>
      <p className="pUnderTitle">
              {t("anything_else2")}

       {/* Si no estás seguro podrás indicarlo más adelante */}
       </p>
      <div>
        <Radio.Group className="radioGroupStyle moreToAdd" onChange={onChangeRadio} value={value}>
          <Space direction="vertical">
            <Radio value="no">        {t("no")}
</Radio>
            <Radio value="yes">        {t("yes")}
</Radio>
            {value === "yes" && <TextArea rows={5} style={{ width: "300px", marginLeft: 10 }} placeholder={t("write_here")} value={prefill?.final_comment_value} onChange={(e: any) => handleChange("final_comment_value", e.target.value)} />}
          </Space>
        </Radio.Group>
      </div>
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.final_comment || (prefill?.final_comment === "yes" && !prefill?.final_comment_value)} onClick={() => handleNext("rest-reservation-11", "rest-tryit-0")}>
            {/* <Button type="primary" className="nextButton" disabled={!prefill?.final_comment || (prefill?.final_comment === "yes" && !prefill?.final_comment_value)} onClick={() => handleNext("rest-reservation-11", "rest-legal-0")}> */}
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};
