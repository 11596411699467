import axios from "axios";
import { auth } from "@doar/main/src/config/firebase";

// console.log(process.env);
let baseURL = "";
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  baseURL = "https://europe-west3-bookline-dev.cloudfunctions.net/api";
} else if (process.env.REACT_APP_ENVIRONMENT === "production") {
  baseURL = "https://europe-west3-bookline-pro.cloudfunctions.net/api";
}

const FUNAPI = axios.create({
  baseURL,
});

FUNAPI.interceptors.request.use(
  async (config) => {
    const token = await auth.currentUser?.getIdToken();
    if (token) {
      let configUtil = { ...config.headers };
      delete configUtil.Authorization;
      configUtil["Authorization"] = `Bearer ${token}`;
      config.headers = { ...configUtil };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

FUNAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    if ((error?.response?.status === 0 || error?.response?.status === 401) && !config?.sent) {
      config.sent = true;
      return FUNAPI.request(config);
    }
    return Promise.reject(error);
  }
);

export default FUNAPI;
