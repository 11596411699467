/* eslint-disable */
// puto el que lee

import API from "@doar/shared/methods/api";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { addSeconds } from "date-fns";
import uuid from "react-uuid";
import { v4 as uuidv4 } from "uuid";
import FUNAPI from "@doar/shared/methods/webfun-api";

const voiceOptionsUtil = require("../utils/voice-options.json");
const defaultVoices = require("../utils/default-voices.json");

function convertSchedule(mapsSchedule: any, handover?: boolean) {
  const dayIntMap = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const days: any = {
    Sunday: { shifts: [] },
    Monday: { shifts: [] },
    Tuesday: { shifts: [] },
    Wednesday: { shifts: [] },
    Thursday: { shifts: [] },
    Friday: { shifts: [] },
    Saturday: { shifts: [] },
  };

  const shifts = mapsSchedule.periods.map((el: any) => {
    let end_time = "23:59";
    if (el.close !== undefined) {
      end_time = el.close.time.slice(0, 2) + ":" + el.close.time.slice(2, 4);
    }

    let start_time = el.open.time.slice(0, 2) + ":" + el.open.time.slice(2, 4);
    if (handover) {
      const startTime = new Date(`2000-01-01T${start_time}:00`);
      startTime.setHours(startTime.getHours() - 2);
      start_time = startTime.toTimeString().slice(0, 5);
    }

    return {
      day: el.open.day,
      shift: {
        start_time: start_time,
        end_time: end_time,
      },
    };
  });

  for (const shift of shifts) {
    const dayStr = dayIntMap[shift.day];
    const dayShifts = days[dayStr].shifts;

    if (dayShifts.length === 0 || shift.shift.start_time > dayShifts[dayShifts.length - 1].end_time) {
      // If no previous shifts or non-overlapping, add the current shift as is
      dayShifts.push(shift.shift);
    } else {
      // Merge overlapping shifts into one larger shift
      dayShifts[dayShifts.length - 1].end_time = shift.shift.end_time;
    }
  }

  for (const day in days) {
    if (days[day].shifts.length === 0) {
      days[day].shifts.push({ start_time: "", end_time: "" });
    }
  }

  // console.log("Schedule converted", days);

  return days;
}

export async function createBot(config: any, navigateTo?: any, handleChange?: any, handleFinish?: any) {
  async function assignNumber(bot?: any) {
    const freeNumber = await getFreeNumbers();

    if (freeNumber) {
      const assignment = {
        id: `${freeNumber}-PHONE`,
        channel_id: freeNumber,
        channel_type: "PHONE",
        bot_id: bot.id,
        owner_id: "bookline",
        config_override: {},
      };
      API.post("/assignments", assignment)
        .then((res) => {
          console.log("AXIOS assignment", res.data);

          let configUtil = JSON.parse(JSON.stringify(config));
          configUtil["botNumber"] = res.data.channel_id;
          configUtil["bot_id"] = bot.id;

          handleChange(null, configUtil, "rest-tryit-1");
        })
        .catch((err) => console.log(err));
    } else {
      let configUtil = JSON.parse(JSON.stringify(config));
      configUtil["botNumber"] = "34000000000";
      configUtil["bot_id"] = bot.id;

      handleChange(null, configUtil, "rest-tryit-1");
      alert("Actualmente no hay números libres para asignar");
    }
  }

  async function getAssignments(offset: number) {
    const response: any = await API.get(`/assignments/ids?offset=${offset}`);
    // console.log("response en getAssignments", response);

    return response.data;
  }

  async function getNumbers(offset: number) {
    const response: any = await API.get(`/numbers/ids?number_type=PSTN&status=ACTIVE&platform=JAMBONZ&carrier=VOXBONE&offset=${offset}`);
    // console.log("response en getNumbers", response);

    return response.data;
  }

  async function getAllNumbers() {
    let response_data = await getNumbers(0);
    // console.log("RESPONSE PRIMERA NUMBERS", response_data);

    let results = response_data.data;
    // results.push(response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises = [];
      while (offset < response_data.pagination.total) {
        promises.push(getNumbers(offset));
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  async function getAllAssignments() {
    let response_data = await getAssignments(0);
    // console.log("RESPONSE PRIMERA ASSIGNMENTS", response_data);
    let results = response_data.data;
    // let results = [];
    // results.push(response_data.data);
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises = [];
      while (offset < response_data.pagination.total) {
        promises.push(getAssignments(offset));
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    return results;
  }
  async function getFreeNumbers() {
    let response_numbers = await getAllNumbers();
    let response_assignments = await getAllAssignments();

    const notAssignedNumber = response_numbers.find((number: string) => {
      if (number.startsWith("34")) {
        const isSubstringAssigned = response_assignments.some((element: string) => element.includes(number));
        return !isSubstringAssigned;
      }
      return false;
    });
    return notAssignedNumber;
  }

  let defaultLanguages: any = {
    spanish: {
      title: "Spanish",
      language_code: "es_ES",
      voice: "es-ES-Lucia",
      voice_provider: 2,
      enabled: {
        value: false,
      },
      voice_speed: 1.1,
      volume: "x-loud",
    },
    english: {
      title: "English",
      language_code: "en_US",
      voice: "en-US-Joanna",
      voice_provider: 2,
      enabled: {
        value: false,
      },
      voice_speed: 1.1,
      volume: "x-loud",
    },
    catalan: {
      title: "Catalan",
      language_code: "ca_ES",
      voice: "ca-ES-AlbaNeural",
      voice_provider: 1,
      enabled: {
        value: false,
      },
      voice_speed: 1.1,
      volume: "x-loud",
    },
    german: {
      title: "German",
      language_code: "de_DE",
      voice: "de-DE-Wavenet-A",
      voice_provider: 0,
      enabled: {
        value: false,
      },
      voice_speed: 1.1,
      volume: "x-loud",
    },
    french: {
      title: "French",
      language_code: "fr_FR",
      voice: "fr-FR-Wavenet-A",
      voice_provider: 0,
      enabled: {
        value: false,
      },
      voice_speed: 1.1,
      volume: "x-loud",
    },
    portuguese: {
      title: "Portuguese",
      language_code: "pt_PT",
      voice: "pt-PT-Ines",
      voice_provider: 2,
      enabled: {
        value: false,
      },
      voice_speed: 1.0,
      volume: "x-loud",
    },
    italian: {
      title: "Italian",
      language_code: "it_IT",
      voice: "it-IT-Wavenet-B",
      voice_provider: 0,
      enabled: {
        value: false,
      },
      voice_speed: 1.0,
      volume: "x-loud",
    },
  };

  // Reemplazo el objeto de voz  default por la voz que eligió el usuario
  let defLagObj = JSON.parse(JSON.stringify(defaultLanguages[config.default_language]));
  defLagObj.voice = config.voice_name;
  defLagObj.voice_provider = getVoiceProvider();
  defaultLanguages[config.default_language] = defLagObj;

  function convertQuestion(e: string) {
    switch (e) {
      case "1":
        return "¿Algún comentario adicional como alergias, intolerancias o peticiones especiales que quieras indicar?";

      case "2":
        return "En caso de venir con niños y necesitar tronas, por favor especifique cuántas necesitas.";

      case "3":
        return "¿Cómo nos ha conocido?";

      case "4":
        return "¿Viene alguna persona con movilidad reducida?";
      default:
        return "";
    }
  }

  function getCarrier() {
    switch (config?.tel_provider) {
      case "movistar":
        return 1;
      case "orange":
        return 2;
      case "vodafone":
        return 4;
      default:
        return 0;
    }
  }

  async function getObsevationQuestions(questionList: any) {
    const questions: any = {
      1: {
        question_json: `{\"es-ES\":\"¿Algún comentario adicional como alergias, intolerancias o peticiones especiales que quieras indicar?\",\"en-US\":\"Would you like to add any additional comments such as allergies, intolerances or further requests?\",\"ca-ES\":\"Algun comentari addicional com alèrgies, intoleràncies o peticions especials que vulguis indicar?\", \"fr-FR\":\"Avez-vous des commentaires supplémentaires tels que des allergies, des intolérances ou des demandes spéciales?\", \"de-DE\":\"Möchten Sie zusätzliche Bemerkungen wie Allergien, Unverträglichkeiten oder weitere Wünsche hinzufügen?\", \"it-IT\":\"Ha qualche commenti aggiuntivi come allergie, intolleranze o richieste particolari che vorrebbe segnalare?\", \"pt-PT\":\"Tem algum comentário adicional, como alergias, intolerâncias ou pedidos especiais que gostaria de informar?\"}`,
        observation_header: "Comentarios adicionales",
        answer_type: 0,
        question_type: 1,
        question_id: 0,
      },
      2: {
        question_json: `{\"es-ES\":\"En caso de venir con niños y necesitar tronas, por favor especifique cuántas necesitas.\", \"en-US\":\"In case you come with children and need highchairs, please specify how many you need.\", \"ca-ES\":\"En cas de venir amb infants i necessitar trones, si us plau, especifiqui quantes en necessitarà.\", \"fr-FR\":\"Si vous venez avec des enfants et avez besoin de chaises hautes, veuillez préciser combien vous en avez besoin.\", \"de-DE\":\"Wenn Sie mit Kindern kommen und Hochstühle benötigen, geben Sie bitte an, wie viele Sie benötigen.\", \"it-IT\":\"In caso di venire con bambini e aver bisogno di seggioloni, si prega di specificare quanti ne sono necessari.\", \"pt-PT\":\"No caso de vir com crianças e precisar de cadeiras altas, por favor, especifique quantas são necessárias.\"}`,
        observation_header: "Tronas para niños",
        answer_type: 0,
        question_type: 1,
        question_id: 0,
      },
      3: {
        question_json: `{\"es-ES\":\"¿Cómo nos ha conocido?\", \"ca-ES\":\"Com ens has conegut?\", \"en-US\":\"How did you hear about us?\", \"fr-FR\":\"Comment avez-vous entendu parler de nous ?\", \"de-DE\":\"Wie haben Sie von uns erfahren?\", \"it-IT\":\"Come ci ha conosciuto?\", \"pt-PT\":\"Como nos conheceu?\"}`,
        observation_header: "Cómo nos ha conocido",
        answer_type: 0,
        question_type: 1,
        question_id: 0,
      },
      4: {
        question_json: `{\"es-ES\":\"¿Viene alguna persona con movilidad reducida?\", \"ca-ES\":\"Ve alguna persona amb mobilitat reduïda?\", \"en-US\":\"Is anyone coming with reduced mobility?\", \"fr-FR\":\"Est-ce que quelqu'un a une mobilité réduite ?\", \"de-DE\":\"Kommt jemand mit eingeschränkter Mobilität?\", \"it-IT\":\"Viene qualcuno con mobilità ridotta?\", \"pt-PT\":\"Vem alguém com mobilidade reduzida?\"}`,
        observation_header: "Movilidad reducida",
        answer_type: 0,
        question_type: 1,
        question_id: 0,
      },
    };

    const Formalquestions: any = {
      1: {
        question_json: `{\"es-ES\":\"¿Algún comentario adicional como alergias, intolerancias o peticiones especiales que quiera indicar?\",\"en-US\":\"Would you like to add any additional comments such as allergies, intolerances or further requests?\",\"ca-ES\":\"Algun comentari addicional com al·lèrgies, intoleràncies o peticions especials que vulgui indicar?\", \"fr-FR\":\"Avez-vous des commentaires supplémentaires tels que des allergies, des intolérances ou des demandes spéciales?\", \"de-DE\":\"Möchten Sie zusätzliche Bemerkungen wie Allergien, Unverträglichkeiten oder weitere Wünsche hinzufügen?\", \"it-IT\":\"Ha qualche commento aggiuntivo come allergie, intolleranze o richieste particolari che vorrebbe segnalare?\", \"pt-PT\":\"Tem algum comentário adicional, como alergias, intolerâncias ou pedidos especiais que gostaria de informar?\"}`,
        observation_header: "Comentarios adicionales",
        answer_type: 0,
        question_type: 1,
        question_id: 0,
      },
      2: {
        question_json: `{\"es-ES\":\"En caso de venir con niños y necesitar tronas, por favor especifique cuántas necesita.\", \"en-US\":\"In case you come with children and need highchairs, please specify how many you need.\", \"ca-ES\":\"En cas de venir amb infants i necessitar trones, si us plau, especifiqui quantes en necessitarà.\", \"fr-FR\":\"Si vous venez avec des enfants et avez besoin de chaises hautes, veuillez préciser combien vous en avez besoin.\", \"de-DE\":\"Wenn Sie mit Kindern kommen und Hochstühle benötigen, geben Sie bitte an, wie viele Sie benötigen.\", \"it-IT\":\"In caso di venire con bambini e aver bisogno di seggioloni, si prega di specificare quanti ne sono necessari.\", \"pt-PT\":\"No caso de vir com crianças e precisar de cadeiras altas, por favor, especifique quantas são necessárias.\"}`,
        observation_header: "Tronas para niños",
        answer_type: 0,
        question_type: 1,
        question_id: 0,
      },
      3: {
        question_json: `{\"es-ES\":\"¿Cómo nos ha conocido?\", \"ca-ES\":\"Com ens ha conegut?\", \"en-US\":\"How did you hear about us?\", \"fr-FR\":\"Comment avez-vous entendu parler de nous ?\", \"de-DE\":\"Wie haben Sie von uns erfahren?\", \"it-IT\":\"Come ci ha conosciuto?\", \"pt-PT\":\"Como nos conheceu?\"}`,
        observation_header: "Cómo nos ha conocido",
        answer_type: 0,
        question_type: 1,
        question_id: 0,
      },
      4: {
        question_json: `{\"es-ES\":\"¿Viene alguna persona con movilidad reducida?\", \"ca-ES\":\"Ve alguna persona amb mobilitat reduïda?\", \"en-US\":\"Is anyone coming with reduced mobility?\", \"fr-FR\":\"Est-ce que quelqu'un a une mobilité réduite ?\", \"de-DE\":\"Kommt jemand mit eingeschränkter Mobilität?\", \"it-IT\":\"Viene qualcuno con mobilità ridotta?\", \"pt-PT\":\"Vem alguém com mobilidade reduzida?\"}`,
        observation_header: "Movilidad reducida",
        answer_type: 0,
        question_type: 1,
        question_id: 0,
      },
    };

    // Seleccionar qué conjunto de preguntas usar
    const selectedQuestions = config?.assistant_tuteation === "usted" ? Formalquestions : questions;

    if (questionList) {
      let questionUtil = questionList.map((q: string) => selectedQuestions[+q]);
      if (config?.extra_question_custom) {
        const customText = await handleTranslation(config?.extra_question_custom);
        console.log("translated text", customText);

        questionUtil.push({
          question_json: `{\"es-ES\":\"${config?.extra_question_custom}\",\"en-US\":\"${customText.en}\", \"ca-ES\":\"${customText.ca}\", \"fr-FR\":\"${customText.fr}\", \"de-DE\":\"${customText.de}\"}`,
          observation_header: "Pregunta personalizada",
          answer_type: 0,
          question_type: 1,
          question_id: 0,
        });
      }
      console.log("observation questions", questionUtil);
      return questionUtil;
    }

    return [];
  }

  function getCurrentShiftPolicy() {
    if (config?.same_shift === "transfer_restaurant") return 2;
    else if (config?.same_shift === "allowed") return 0;
    else if (config?.same_shift === "not_allowed_go_to_restaurant") return 1;
    else if (config?.same_shift === "not_allowed_custom") return 1;
    return 0;
  }

  async function getCurrentShiftPolicyCustomMessage() {
    if (config?.same_shift === "not_allowed_go_to_restaurant")
      return '{"es-ES":"Ya no se aceptan reservas para ese turno, pero nuestro personal le atenderá personalmente en nuestro restaurante", "ca-ES":"Ya no s\'accepten reserves per a aquest torn, però el nostre personal l\'atendrà personalment al nostre restaurant", "en-US":"Reservations for that time slot are no longer accepted, but our staff will personally assist you at our restaurant", "fr-FR":"Les réservations pour ce créneau horaire ne sont plus acceptées, mais notre personnel vous accueillera personnellement dans notre restaurant", "de-DE":"Reservierungen für diesen Zeitraum werden nicht mehr angenommen, aber unser Personal wird Sie persönlich in unserem Restaurant betreuen"}';
    else if (config?.same_shift === "not_allowed_custom") {
      const customText = await handleTranslation(config?.same_shift_custom);
      return `{\"es-ES\":\"${config?.same_shift_custom}\",\"en-US\":\"${customText.en}\", \"ca-ES\":\"${customText.ca}\", \"fr-FR\":\"${customText.fr}\", \"de-DE\":\"${customText.de}\"}`;
      // return `{"es-ES":"${config?.same_shift_custom}", "ca-ES":"${config?.same_shift_custom}", "en-US":"${config?.same_shift_custom}", "fr-FR":"${config?.same_shift_custom}", "de-DE":"${config?.same_shift_custom}"}`;
    }
    return "";
  }

  function getNoAvailConfig() {
    // RESTAURANT = 0;
    // HANDOVER = 1;
    // CUSTOM = 2;
    // NONE = 3;

    let schSource = 3;
    if (config?.no_availability_transfer === "open") schSource = 0;
    else if (config?.no_availability_transfer === "custom") schSource = 2;
    else if (config?.no_availability_transfer === "handover") schSource = 1;

    return {
      transfer_call: config?.no_availability_transfer !== "no",
      schedule_source: schSource,
      custom_transfer_schedule:
        config?.no_availability_transfer === "custom"
          ? config?.no_availability_transfer_schedule
          : {
              days: {},
              exceptions: [],
              always_enabled: {
                value: false,
              },
            },
      custom_message_transfer:
        config?.no_availability_transfer !== "no"
          ? `{"es-ES":"${config?.no_availability_transfer_message}", "ca-ES":"${config?.no_availability_transfer_message}", "en-US":"${config?.no_availability_transfer_message}", "fr-FR":"${config?.no_availability_transfer_message}", "de-DE":"${config?.no_availability_transfer_message}"}`
          : "",
      custom_message_no_transfer:
        config?.no_availability_transfer === "no"
          ? `{"es-ES":"${config?.no_availability_no_transfer_message}", "ca-ES":"${config?.no_availability_no_transfer_message}", "en-US":"${config?.no_availability_no_transfer_message}", "fr-FR":"${config?.no_availability_no_transfer_message}", "de-DE":"${config?.no_availability_no_transfer_message}"}`
          : "",
    };
  }
  function getProposalPolicies() {
    // NONE = 0;    // No proposals
    // SAME_DAY_SAME_SHIFT = 1;   // Same day same shift only - Make proposals for the same day and same shift in the same restaurant
    // SAME_DAY = 2;     // Same day - Make proposals for the same day in the same restaurant
    // SAME_WEEK_SAME_SHIFT = 3;    // Same week same shift only - Make proposals for the same week and same shift in the same restaurant
    // SAME_WEEK = 4;    // Same week - Make proposals for the same week in the same restaurant
    // GROUP = 5;   // Cross Selling - Cross-selling between restaurants from the same group (Cover Manager only)
    // HANDOVER = 6;   // Handover option
    // WAITLIST = 7;
    // CUSTOM_MESSAGE = 8;
    if (config?.reservation_policy?.length) {
      return config?.reservation_policy.filter((pol: any) => pol.checked).map((poli: any) => poli.id);
    }
    return [];
  }
  function getHandoverScheduleWith2HoursLess() {
    if (config?.google?.opening_hours) {
      let sch = {
        days: convertSchedule(config?.google?.opening_hours, true),
        exceptions: [],
        always_enabled: {
          value: false,
        },
      };
      // console.log("google schedule", convertSchedule(config?.google?.opening_hours));

      // console.log("schedule converted with 2 hours less", sch);
      return sch;
    }
    return {
      days: {
        Thursday: {
          shifts: [
            {
              start_time: "11:00",
              end_time: "15:00",
            },
            {
              start_time: "17:00",
              end_time: "23:00",
            },
          ],
        },
        Saturday: {
          shifts: [
            {
              start_time: "11:00",
              end_time: "15:00",
            },
            {
              start_time: "17:00",
              end_time: "23:00",
            },
          ],
        },
        Sunday: {
          shifts: [
            {
              start_time: "11:00",
              end_time: "15:00",
            },
            {
              start_time: "17:00",
              end_time: "23:00",
            },
          ],
        },
        Wednesday: {
          shifts: [
            {
              start_time: "11:00",
              end_time: "15:00",
            },
            {
              start_time: "17:00",
              end_time: "23:00",
            },
          ],
        },
        Monday: {
          shifts: [
            {
              start_time: "11:00",
              end_time: "15:00",
            },
            {
              start_time: "17:00",
              end_time: "23:00",
            },
          ],
        },
        Tuesday: {
          shifts: [
            {
              start_time: "11:00",
              end_time: "15:00",
            },
            {
              start_time: "17:00",
              end_time: "23:00",
            },
          ],
        },
        Friday: {
          shifts: [
            {
              start_time: "11:00",
              end_time: "15:00",
            },
            {
              start_time: "17:00",
              end_time: "23:00",
            },
          ],
        },
      },
      exceptions: [],
      always_enabled: {
        value: false,
      },
    };
  }

  function getHandoverSchedule() {
    if (!config?.advanced && config?.when_answer === "always") {
      return [
        {
          phone: config?.handover_phone ?? config?.phone?.replace(/ /g, "") ?? "+34666666666",
          schedule: getHandoverScheduleWith2HoursLess(),
          name: "Default",
          context: "voxbone",
          phonetics: [],
          custom_name: "",
          priority: 1,
          operator: "",
          caller_id: "",
          handover_music_type: 1,
          custom_handover_music_url: "",
          whisper_message: false,
          custom_whisper_message: "",
        },
      ];
    } else if (config?.when_handover === "scheduled")
      return [
        {
          phone: config?.handover_phone,
          schedule: config?.when_handover_schedule,
          name: "Default",
          context: "voxbone",
          phonetics: [],
          custom_name: "",
          priority: 1,
          operator: "",
          caller_id: "",
          handover_music_type: 1,
          custom_handover_music_url: "",
          whisper_message: false,
          custom_whisper_message: "",
        },
      ];
    else if (config?.when_handover === "always") {
      return [
        {
          phone: config?.handover_phone,
          schedule: { always_enabled: { value: true }, days: {}, exceptions: [] },
          name: "Default",
          context: "voxbone",
          phonetics: [],
          custom_name: "",
          priority: 1,
          operator: "",
          caller_id: "",
          handover_music_type: 1,
          custom_handover_music_url: "",
          whisper_message: false,
          custom_whisper_message: "",
        },
      ];
    } else return [];
  }

  function whenHandOver() {
    if (config?.when_answer === "always" && !!config?.handover_phone) {
      return {
        enabled: true,
        custom_message_no_handover: "",
        phones_with_schedule: [
          {
            phone: config?.handover_phone?.replace(/ /g, ""),
            schedule: {
              always_enabled: { value: true },
            },
          },
        ],
      };
    } else if (config?.when_answer === "always" && !config?.handover_phone) {
      return {
        enabled: false,
      };
    } else if (config?.when_answer != "always") {
      return {
        enabled: true,
        custom_message_no_handover: "",
        phones_with_schedule: [
          {
            phone: config?.phone?.replace(/ /g, "") ?? "",
            schedule: {
              always_enabled: { value: true },
            },
          },
        ],
      };
    }
  }

  function getVoiceProvider() {
    switch (config?.voice_name) {
      case "es-ES-Sergio":
      case "es-ES-Lucia":
      case "ca-ES-Arlet":
      case "de-DE-Daniel":
      case "de-DE-Vicki":
      case "pt-PT-Cristiano":
      case "pt-PT-Thiago":
      case "pt-PT-Ines":
      case "pt-PT-Victoria":
      case "pt-PT-Camila":
      case "en-US-Matthew":
      case "en-US-Stephen":
      case "en-US-Joanna":
      case "en-US-Ruth":
      case "fr-FR-Remi":
      case "fr-FR-Lea":
      case "it-IT-Bianca":
      case "it-IT-Adriano":
        return 2;

      case "es-ES-Neural2-F":
      case "es-ES-Wavenet-B":
      case "es-ES-Neural2-B":
      case "es-ES-Neural2-A":
      case "es-ES-Neural2-D":
        return 0;

      case "es-ES-LaiaNeural":
      case "ca-ES-EnricNeural":
        return 1;

      default:
        return 2;
    }
    // class VoiceProvider(IntEnum):
    //      GOOGLE = 0
    //      MICROSOFT = 1
    //      AMAZON = 2
    //      VERBIO = 3
  }

  function getCustomGreeting() {
    const customGreetingIVR = {
      "es-ES": "$GREETING $USER_NAME. ¿Llama para una reserva, modificar o cancelar una existente, o en qué le puedo ayudar?",
      "ca-ES": "$GREETING $USER_NAME. Truca per fer una reserva, modificar o cancel·lar una existent, o en què et puc ajudar?",
      "en-US": "$GREETING $USER_NAME. Are you calling to make a reservation, modify or cancel an existing one, or how can I help you?",
      "de-DE": "$GREETING $USER_NAME. Rufen Sie an, um eine Reservierung vorzunehmen, eine bestehende zu ändern oder zu stornieren, oder wie kann ich Ihnen helfen?",
      "fr-FR": "$GREETING $USER_NAME. Appelez-vous pour faire une réservation, modifier ou annuler une existante, ou comment puis-je vous aider?",
      "it-IT": "$GREETING $USER_NAME. Chiami per fare una prenotazione, modificare o annullare una esistente, o in cosa posso aiutarti?",
      "pt-PT": "$GREETING $USER_NAME. Está a ligar para fazer uma reserva, modificar ou cancelar uma existente, ou como posso ajudar?",
    };
    const customGreetingNoIVR = {
      "es-ES": "$GREETING $USER_NAME. Estás hablando con $RESTAURANT_NAME! ¿Llama para una reserva, modificar o cancelar una existente, o en qué le puedo ayudar?",
      "ca-ES": "$GREETING $USER_NAME. Estàs parlant amb $RESTAURANT_NAME! Truca per fer una reserva, modificar o cancel·lar una existent, o en què et puc ajudar?",
      "en-US": "$GREETING $USER_NAME. You're speaking with $RESTAURANT_NAME! Are you calling to make a reservation, modify or cancel an existing one, or how can I help you?",
      "de-DE": "$GREETING $USER_NAME. Sie sprechen mit $RESTAURANT_NAME! Rufen Sie an, um eine Reservierung vorzunehmen, eine bestehende zu ändern oder zu stornieren, oder wie kann ich Ihnen helfen?",
      "fr-FR": "$GREETING $USER_NAME. Vous êtes chez $RESTAURANT_NAME! Appelez-vous pour faire une réservation, modifier ou annuler une existante, ou comment puis-je vous aider?",
      "it-IT": "$GREETING $USER_NAME. Sei in contatto con $RESTAURANT_NAME! Chiami per fare una prenotazione, modificare o annullare una esistente, o in cosa posso aiutarti?",
      "pt-PT": "$GREETING $USER_NAME. Está a falar com $RESTAURANT_NAME! Está a ligar para fazer uma reserva, modificar ou cancelar uma existente, ou como posso ajudar?",
    };
    return config?.language_detection != "prefix" && config?.available_languages.length > 1 ? JSON.stringify(customGreetingIVR) : JSON.stringify(customGreetingNoIVR);
  }

  function getLanguageDetectionValue() {
    const isPrefixDetection = config?.language_detection === "prefix";
    const hasMultipleLanguages = config?.available_languages.length > 1;

    if (isPrefixDetection) {
      return { value: true };
    }

    if (!isPrefixDetection && hasMultipleLanguages) {
      return { value: false };
    }

    return { value: false };
  }

  function getRestNotifications() {
    // if (config?.booking_manager === "none" || config?.booking_manager === "other") {
    //   if (config?.notifications_channel === "whatsapp") return [2];
    //   if (config?.notifications_channel === "mail") return [0];
    // }

    return config?.notifications_channel?.map((noti: string) => {
      if (noti === "whatsapp") return "2";
      else return "0";
    });
  }
  // function getMail() {
  //   if (config?.booking_manager === "none" || config?.booking_manager === "other") {
  //     if (config?.notifications_channel === "mail") return [config?.notifications_channel_value];
  //   }
  //   return "";
  // }
  // function getNotificationPhone() {
  //   if (config?.booking_manager === "none" || config?.booking_manager === "other") {
  //     if (config?.notifications_channel === "whatsapp") return [config?.notifications_channel_value];
  //   }
  //   return [];
  // }

  async function translateText(sourceText: string, targetLanguage: string) {
    const apiKey = "AIzaSyA8nPHH295Oynfk2k4M09-5PSw8BV909_I"; // Replace with your Google Translate API key
    const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

    const requestBody = {
      q: sourceText,
      target: targetLanguage,
    };

    try {
      const response = await axios.post(apiUrl, requestBody);
      const translatedText = response.data.data.translations[0].translatedText;
      return translatedText;
    } catch (error) {
      console.error("Translation request error:", error);
      throw error;
    }
  }

  async function handleTranslation(sourceText: string) {
    const targetLanguages = ["en", "ca", "fr", "de"]; // Example target languages

    const translations: any = {};

    for (const lang of targetLanguages) {
      try {
        const translation = await translateText(sourceText, lang);
        translations[lang] = translation;
      } catch (error) {
        translations[lang] = sourceText;
        console.error(`Translation error for language ${lang}:`, error);
      }
    }
    return translations;
  }
  function getClientNotificationChannel() {
    if (config?.send_confirmation === "whatsapp-free" || config?.send_confirmation === "whatsapp-paid") return [2];
    if (config?.send_confirmation === "sms") return [1];
    return [];
  }
  function getClientNotificationChannel2() {
    if (config?.send_confirmation === "whatsapp-free" || config?.send_confirmation === "whatsapp-paid") return ["WhatsApp"];
    if (config?.send_confirmation === "sms") return ["SMS"];
    return [];
  }

  let botsOffset = 0;
  let botsAllData: any = [];

  async function fetchBotsData(): Promise<any> {
    try {
      const response = await API.get(`/bots/ids?offset=${botsOffset}`);
      console.log("response in fetchBotsData", response);

      const data = response.data.data;
      botsAllData = [...botsAllData, ...data];
      botsOffset += data.length;

      if (botsAllData.length < response.data.pagination.total) {
        const result = await fetchBotsData(); // Await the recursive call and return its result
        return result;
      } else {
        return botsAllData; // Resolve the Promise with the finalBotData
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function parseCountryCode() {
    const fullLanguageCode = defaultLanguages[config?.default_language].language_code ? defaultLanguages[config?.default_language].language_code : "es-ES";
    const countryCode = fullLanguageCode.split("_")[1];
    return countryCode;
  }

  function getBackendName() {
    const bookingManagers: { [key: string]: string } = {
      cover: "cover",
      restoo: "restoo",
      fork: "thefork",
    };
    return bookingManagers[config?.booking_manager] || "none";
  }

  function getBackendUser() {
    const testUserIds: { [key: string]: string } = {
      cover: "bookline-integracion1",
      restoo: "demo",
      fork: "4e79f794-62bd-40d4-9f24-78644edb3d56",
    };
    return testUserIds[config?.booking_manager] || "";
  }

  function getPhoneCarrier() {
    const phoneCarriers: { [key: string]: string } = {
      movistar: "Movistar",
      vodafone: "Vodafone",
      orange: "Orange",
    };
    return phoneCarriers[config?.tel_provider] || "";
  }

  function getDefaultLanguage() {
    const defaultLang = defaultLanguages[config?.default_language];
    return defaultLang;
  }

  // {
  //   "id": "string",
  //   "name": "string",
  //   "customer_id": "string",
  //   "crm_id": "string",
  //   "type": "RESTAURANT"
  // }

  // Creation of a new Venue from customer
  const venueId: string = uuidv4();

  function generateVenueObj() {
    // Mount the object before sending to Papi
    const id = venueId;
    const name = config?.name ?? "";
    const customer_id = config?.user?.permissions?.[0]?.customer_id ?? "";
    const crm_id = "";
    const type = "RESTAURANT";

    const venueObject = { id, name, customer_id, crm_id, type };
    console.log("venueObject", venueObject);
    return venueObject;
  }

  async function createNewVenue() {
    const payload = generateVenueObj();

    try {
      // Espera a que la solicitud post a la API sea resuelta
      const res = await API.post("/venues", payload);

      // Captura de evento para un venue creado
      Sentry.captureEvent({
        message: "Created Venue",
        level: "info", // Nivel apropiado para el evento
        extra: {
          customProperty: res, // Datos adicionales para el evento
        },
      });

      console.log("#CREATE-BOT: AXIOS CREATE VENUE", res);
      return true; // Devolver true si la creación del venue fue exitosa
    } catch (err) {
      // Captura de evento en caso de fallo
      Sentry.captureEvent({
        message: "Venue creation failed",
        level: "error", // Nivel de error apropiado
        extra: {
          customProperty: err, // Datos adicionales para el evento
        },
      });

      console.log(err); // Registro de error en consola
      return false; // Devolver false si hubo un error
    }
  }

  async function generateBotId(customerID: string) {
    // No se va a chequear el id, se va a intentar el post con el customer id y si falla, se intenta con otro
    // const botList = await fetchBotsData();

    // if (botList.includes(customerID)) {
    //   let idNumber = 2;
    //   let newID = customerID + "-" + idNumber;
    //   while (botList.includes(newID)) {
    //     idNumber++;
    //     newID = customerID + "-" + idNumber;
    //   }
    //   return newID;
    // } else {
    //   return customerID;
    // }
    return customerID;
  }
  const botID = await generateBotId(config.user.permissions[0].customer_id);
  // const botID = config.user.permissions[0].customer_id + "-bot-" + Math.floor(Math.random() * 1000);

  function getLangGreeting(lang: any) {
    const greetingText = {
      SPANISH: `Hola está hablando con ${config?.name}`,
      ITALIAN:  `Ciao, stai parlando con ${config?.name}`,
      CATALAN:  `Hola, està parlant amb ${config?.name}`,
      FRENCH: `Bonjour, vous parlez avec ${config?.name}`,
      PORTUGUESE:  `Olá, está a falar com ${config?.name}`,
      ENGLISH:  `Hello, you are speaking with ${config?.name}`,
      GERMAN: `Hallo, Sie sprechen mit ${config?.name}`,
    };
    
    //@ts-ignore
    return greetingText[lang];
  }
  

  function getLangText(lang: any, mode: any, dtmfIndex: any): any {
    const languageTexts = {
      SPANISH: {
        dtmf: "Para hablar en español, marque",
        speech: "Para hablar español, diga Español",
        both: "Para hablar en español, marque o diga Español",
      },
      ENGLISH: {
        dtmf: "To speak in English, press",
        speech: "To speak English, say English",
        both: "To speak in English, press or say English",
      },
      CATALAN: {
        dtmf: "Per parlar en català, marqueu",
        speech: "Per parlar català, digueu Català",
        both: "Per parlar en català, marqueu o digueu Català",
      },
      FRENCH: {
        dtmf: "Pour parler français, appuyez sur",
        speech: "Pour parler français, dites Français",
        both: "Pour parler français, appuyez sur 1 ou dites Français",
      },
      GERMAN: {
        dtmf: "Um Deutsch zu sprechen, drücken Sie",
        speech: "Um Deutsch zu sprechen, sagen Sie Deutsch",
        both: "Um Deutsch zu sprechen, drücken Sie oder sagen Sie Deutsch",
      },
      ITALIAN: {
        dtmf: "Per parlare in italiano, premere",
        speech: "Per parlare italiano, dica Italiano",
        both: "Per parlare in italiano, premere o dica Italiano",
      },
      PORTUGUESE: {
        dtmf: "Para falar em português, pressione",
        speech: "Para falar português, diga Português",
        both: "Para falar em português, pressione ou diga Português",
      },
    };

    // @ts-ignore
    const textConfig: any = languageTexts[lang];

    const basicText = `${textConfig.dtmf} ${dtmfIndex}`;
    let text;
    if (mode === "both") {
      const bothParts = textConfig.both.split(textConfig.dtmf);
      text = `${textConfig.dtmf} ${dtmfIndex}${bothParts[1]}`;
    } else if (mode === "dtmf") {
      text = basicText;
    } else {
      text = textConfig.speech;
    }

    return text;
  }

  // Creation of venue before creating bot
  function generateLanguageAudioConfig(): any {
    const languages = config?.available_languages.map((lang: string) => lang.toUpperCase());
    const defaultLanguage = config?.default_language.toUpperCase();
    console.log("#CreateBot - AudioIVRGeneration: Default language", defaultLanguage);
    const langsMap: any = defaultVoices;
    console.log("#CreateBot - AudioIVRGeneration: Selected languages", languages);

    const selectedLangs: any = [defaultLanguage, ...languages.filter((lang: string) => lang !== defaultLanguage)];

    console.log("#CreateBot - AudioIVRGeneration: Selected languages", selectedLangs);

    const voices = selectedLangs.map((lang: string) => {
      if (langsMap[lang]) {
        return langsMap[lang]; // Si el lenguaje está en langsMap, devuelvo la configuración
      } else {
        console.warn(`Warning: Language ${lang} not set`); // Mensaje de advertencia en la consola
        return null; // Si no existe el lenguaje, devuelvo null
      }
    });
    console.log("#CreateBot - AudioIVRGeneration: Voices to Generate", voices);
    if (voices.some((voice: any) => voice === null)) {
      return null; // Si algún lenguaje no está configurado, retornamos null
    } else {
      // Construir el modelo para cada voz válida
      const defaultVoiceConfig = langsMap[defaultLanguage]?.tts;

      if (!defaultVoiceConfig) {
        console.error(`Default language ${defaultLanguage} is not configured.`);
        return null;
      }

      // Crear el saludo utilizando la configuración del idioma predeterminado
      const greetingVoice = {
        code: defaultVoiceConfig.voice_title, 
        provider: defaultVoiceConfig.provider, 
        volume: 1,
        language: defaultVoiceConfig.language, 
        tts: getLangGreeting(defaultVoiceConfig.language), 
        neural: defaultVoiceConfig.neural, 
      };

      // Construir el modelo para cada voz válida
      const voiceModels = voices.map((voice: any, index: number) => {
        return {
          code: voice.tts.voice_title, 
          provider: voice.tts.provider,
          volume: 1, 
          language: voice.tts.language, 
          tts: getLangText(voice.tts.language, "both", index + 1), 
          neural: voiceOptionsUtil.tts.find((vo: any) => vo.code.toLowerCase() === voice.tts.voice_title.toLowerCase())?.neural ?? true, 
        };
      });

      const modelo = {
        initial_pause: 0,
        between_pause: 0,
        final_pause: 0,
        voices: [greetingVoice, ...voiceModels], // Añadir el saludo al principio del array de voces
      };

      console.log("#CreateBot - AudioIVRGeneration: Modelo to send", modelo);

      return modelo; // Retorna el modelo con las voces configuradas
    }
  }

  async function getAudioFromLanguagesUrl() {
    const config = generateLanguageAudioConfig();

    try {
      const urlResponse = await FUNAPI.post("/tts", config);
      console.log("#CreateBot - AudioIVRGeneration: URL RESPONSE:", urlResponse);

      if (urlResponse) {
        console.log("#CreateBot - AudioIVRGeneration: Audio URL:", urlResponse.data.url);
        return urlResponse.data.url;
      } else {
        throw new Error("No response received from the server");
      }
    } catch (err) {
      console.error("Error fetching audio URL:", err);
    }
  }

  // Variable to save languages codes selected
  const availableLanguagesCodes = Object.values(defaultLanguages)
    .filter((lang: any) => config.available_languages.includes(lang.title.toLowerCase()))
    .map((lang: any) => {
      lang.enabled.value = true;
      return lang.language_code;
    });

  function setLangaugesOptionsForIVR(): any {
    const promptsMap: any = {
      es_ES: "español, castellano, hablar, castellà", // Español
      en_US: "english, inglés, talk, inglish, yes", // Inglés
      ca_ES: "català, catalán, parlar, catalan", // Catalán
      de_DE: "deutsch, alemán, sprechen, reden, ja", // Alemán
      fr_FR: "français, francés, parler, causer, oui", // Francés
      pt_PT: "português, portugués, falar, dizer, sim", // Portugués
      it_IT: "italiano, italiano, parlare, discorrere, sì", // Italiano
    };

    const languagesOptions = availableLanguagesCodes.map((code: string, index: number) => ({
      action: "Set language",
      actionParameters: ["", "", ""],
      customPrompt: promptsMap[code],
      customSms: false,
      nextStep: 0,
      promptNumber: index,
      promptParameters: "",
      promptValue: code,
      promptVoiceAlternatives: [],
    }));

    return languagesOptions;
  }

  const bot = {
    id: botID,
    owner_id: config.user.permissions[0].customer_id,
    venue_id: venueId, //TODO Agregar venue id cuando tengamos permisos de la api para endpoint de venues
    name: config?.name ? config?.name : "",
    config: {
      phone: config?.phone ? config.phone?.replace(/ /g, "").replace("+", "00") : "",
      details: {
        // para sin libro
        restaurant_notifications: getRestNotifications() ?? [], // email 0, whatsapp 2
        mail_list: config?.notifications_channel_mail_value?.split(","),
        email_restaurant_notifications_template: 1,
        whatsapp_phones: config?.notifications_channel_whatsapp_value?.split(","),
        pre_greeting_pause: 1.5,
        name: config?.name ?? "",
        title: config?.name ?? "",
        default_language: getDefaultLanguage(),
        // langObj(config?.default_language),
        supported_languages: Object.values(defaultLanguages).map((lang: any) => {
          if (config?.available_languages.includes(lang.title.toLowerCase())) {
            let langUtil = lang;
            langUtil.enabled.value = true;
            return langUtil;
          }
          return lang;
        }),
        // supported_languages: config?.available_languages.map((lang: string) => langObj(lang)),
        default_speed: 1.2,
        mobility_options: [],
        accepted_payment_methods: [],
        address: config?.address ? config?.address : "",
        schedule: config?.google?.opening_hours
          ? {
              days: convertSchedule(config?.google?.opening_hours),
              exceptions: [],
              always_enabled: {
                value: false,
              },
            }
          : {
              days: {
                Thursday: {
                  shifts: [
                    {
                      start_time: "11:00",
                      end_time: "15:00",
                    },
                    {
                      start_time: "17:00",
                      end_time: "23:00",
                    },
                  ],
                },
                Saturday: {
                  shifts: [
                    {
                      start_time: "11:00",
                      end_time: "15:00",
                    },
                    {
                      start_time: "17:00",
                      end_time: "23:00",
                    },
                  ],
                },
                Sunday: {
                  shifts: [
                    {
                      start_time: "11:00",
                      end_time: "15:00",
                    },
                    {
                      start_time: "17:00",
                      end_time: "23:00",
                    },
                  ],
                },
                Wednesday: {
                  shifts: [
                    {
                      start_time: "11:00",
                      end_time: "15:00",
                    },
                    {
                      start_time: "17:00",
                      end_time: "23:00",
                    },
                  ],
                },
                Monday: {
                  shifts: [
                    {
                      start_time: "11:00",
                      end_time: "15:00",
                    },
                    {
                      start_time: "17:00",
                      end_time: "23:00",
                    },
                  ],
                },
                Tuesday: {
                  shifts: [
                    {
                      start_time: "11:00",
                      end_time: "15:00",
                    },
                    {
                      start_time: "17:00",
                      end_time: "23:00",
                    },
                  ],
                },
                Friday: {
                  shifts: [
                    {
                      start_time: "11:00",
                      end_time: "15:00",
                    },
                    {
                      start_time: "17:00",
                      end_time: "23:00",
                    },
                  ],
                },
              },
              exceptions: [],
              always_enabled: {
                value: false,
              },
            },
        web_url: config?.google?.website ? config?.google?.website : "",
        nearby_parking_address: "",
        social_networks: [],
        months_in_advance_limit: 0,
        timezone: "Europe/Madrid",
        backend: config?.booking_manager === "cover" || config?.booking_manager === "restoo" ? config?.booking_manager : "",
        sms_enabled: {
          value: false,
        },
        languages_spoken: [],
        handover_phone: config?.handover_phone?.replace(/ /g, "") ?? "",
        has_glutenfree_option: {
          value: true,
        },
        has_vegan_option: {
          value: true,
        },
        has_vegetarian_option: {
          value: true,
        },
        has_lactoseintolerant_option: {
          value: true,
        },
        average_menu_price: 0.0,
        typical_dish: "",
        handover_diners_limit: 100,
        shift_duration_minutes: config?.reservation_duration ?? 90,
        handover_schedule: getHandoverSchedule(),
        handovers: whenHandOver(),
        bot_schedule: {
          days: {},
          exceptions: [],
          always_enabled: {
            value: true,
          },
        },

        handover_diners_per_booking_limit: 10,
        industry: "restaurants",
        group: "",
        greeting: "synth-custom",
        limit_reservation_hour_midday: "",
        limit_reservation_hour_night: "",
        cross_selling: "",
        // Vanue information phone provider and diversion type
        phone_carrier: getPhoneCarrier(),
        diversion_type: config?.when_answer === "always" ? ["Incondicional"] : ["No contestan"],
        caller_sms: {
          value: false,
        },
        zones_enabled: {
          value: config?.restaurant_zones === "yes" ? true : false,
        },
        proposal_policy: getProposalPolicies(),
        carrier: getCarrier(),
        greeting_type: config?.when_answer === "always" ? 4 : 3,
        current_shift_policy: getCurrentShiftPolicy(),
        current_shift_policy_schedule:
          config?.booking_manager === "restoo"
            ? {
                days: {},
                exceptions: [],
              }
            : config?.current_shift_policy_schedule,
        custom_message_reservation_in_current_shift: await getCurrentShiftPolicyCustomMessage(),
        // voucher_policy: 0,
        // restaurant_notifications: [0],

        // name_alternatives: [""],
        // gdpr: {
        //   value: true,
        // },
        // voicemail: {
        //   value: true,
        // },
        voice_provider: getVoiceProvider(),

        // whitelist: [""],
        // transfer_popup: {
        //   value: true,
        // },

        // true = USTED
        formality: {
          value: config?.assistant_tuteation === "usted",
        },
        // custom_proposal_message: "",
        custom_greeting_message: getCustomGreeting(),
        // custom_greeting_message: await salute(config?.assistant_salute_voice),

        duration_notification: {
          value: config?.shift_duration_policy === "always",
        },

        language_detection: getLanguageDetectionValue(),
        retention_period: config?.reservation_hold_policy ? config.reservation_hold_policy : 0,
        // accepts_reservations: {
        //   value: true,
        // },
        // accepts_delivery: {
        //   value: true,
        // },
        // custom_out_of_schedule_message: "",
        address_lat: config?.google?.geometry?.location?.lat ?? "",
        address_lon: config?.google?.geometry?.location?.lng ?? "",

        // email_validation: {
        //   value: false,
        // },
        // telegram_phones: [],
        // email_validation_mails: [],
        // name_greeting: {
        //   value: false,
        // },
        // custom_no_reservation_message: "",
        // custom_message_before_confirmation: "",
        // zone_priority_list: [],
        // lead_demo_on_slack: {
        //   value: false,
        // },
        // sms_provider: 1,
        whatsapp_sender: "+34931222924",
        // twilio_sid: "",
        // twilio_token: "",
        // reservations_timeframe: 365,
        check_weather: {
          value: config?.say_weather === "yes",
        },
        // special_reservation_terms: {
        //   value: false,
        // },
        // ask_for_stored_name_if_recurring_customer: {
        //   value: false,
        // },
        // too_many_diners_transfer_policy: {
        //   value: true,
        // },
        // custom_message_too_many_diners_no_transfer: "",
        // custom_message_too_many_diners_with_transfer: "",
        listen_dtmf_digits: {
          value: true,
        },
        // email_restaurant_notifications_template: 0,
        // limit_reservation_hour_midday_end: "",
        // limit_reservation_hour_night_end: "",
        // conversation_motive: "",
        // custom_greting_message: "",
        // custom_greeting_url: "",
        // custom_greeting_notes: "",
        use_part_of_day_in_hours: true,
        // gather_email_from_past_reservations: false,
        // customer_service_schedule_source: 0,
        no_availability_transfer_config: {
          transfer_call: config?.when_answer === "always" ? true : false,
          schedule_source: config?.when_answer === "always" ? 1 : 0,
          custom_transfer_schedule: {
            days: {},
            exceptions: [],
            always_enabled: {
              value: false,
            },
          },
          custom_message_transfer: `{"es-ES": "En este momento no le podemos atender, le paso con el restaurante.","ca-ES": "En aquest moment no el podem atendre, el passo amb el restaurant.","en-US": "At this moment we cannot assist you, I will transfer you to the restaurant.","fr-FR": "Pour le moment, nous ne pouvons pas vous assister, je vous transfère au restaurant.","de-DE": "Im Moment können wir Ihnen nicht weiterhelfen, ich verbinde Sie mit dem Restaurant.","it-IT": "Al momento non possiamo aiutarla, le passo il ristorante.","pt-PT": "De momento não podemos atendê-lo, vou transferi-lo para o restaurante."}`,
          custom_message_no_transfer: `{"es-ES":"Parece que para esa hora no hay disponibilidad en este momento, por favor llame en unos minutos para que le podamos atender.","ca-ES":"Sembla que per aquesta hora no hi ha disponibilitat en aquests moments, si us plau, truqueu d'aquí uns minuts perquè puguem ajudar-vos.",  "en-US": "It seems that for that time there is no availability at the moment, please call in a few minutes so that we can assist you.",  "fr-FR": "Il semble qu'à cette heure-ci, il n'y ait pas de disponibilité pour le moment, veuillez appeler dans quelques minutes afin que nous puissions vous aider.",  "de-DE": "Es scheint, dass es momentan keine Verfügbarkeit für diese Uhrzeit gibt. Bitte rufen Sie in ein paar Minuten an, damit wir Ihnen helfen können.", "it-IT": "Sembra che al momento non ci sia disponibilità per quell'orario, chiamate tra qualche minuto così possiamo aiutarla.", "pt-PT": "Parece que para essa hora não há disponibilidade de momento, por favor ligue dentro de alguns minutos para que possamos atendê-lo."}`,
        },
        // no_availability_transfer_config: getNoAvailConfig(),
        // same_week_proposal_policy_lookahead_days: 0,
        // same_week_proposal_policy_lookaround_days: 4,
        // whatsapp_phones: [],
        // notify_reservation_terms_before_confirmation: false,
        // custom_message_before_reservation_terms: "",
        observation_questions: await getObsevationQuestions(config?.extra_questions_data),
        observations_required: {
          value: config?.extra_questions_data?.length > 0,
        },
        pre_booking: null,
        // ms_delay_first_interaction: 0,
        // custom_message_after_confirmation: "",
        // diversion_type: [],
        // basic_information_notes: "",
        whatsapp_bookline_marketing: config?.send_confirmation === "whatsapp-free",
        whatsapp_bookline_marketing_image_url: "https://storage.googleapis.com/bookline-rec/whats_templates_photos/booklinewhats.jpg",
        send_whatsapp_disregard_user_email: true,
        notification_channel: getClientNotificationChannel2(),
        client_notifications: getClientNotificationChannel(),

        custom_handover_disabled_message:
          '{"es-ES":"Ahora mismo no podemos transferir su llamada al restaurante. Por favor, pruebe de nuevo en unos minutos. Muchas gracias!","en-US":"Right now we can\'t transfer your call to the restaurant. Please try again in a few minutes. Thank you!","ca-ES":"Ara mateix no podem transferir la seva trucada al restaurant. Si us plau, intenti-ho de nou en uns minuts. Gràcies!","de-DE":"Im Moment können wir Ihren Anruf nicht an das Restaurant weiterleiten. Bitte versuchen Sie es in einigen Minuten erneut. Danke!","fr-FR":"En ce moment, nous ne pouvons pas transférer votre appel au restaurant. Veuillez réessayer dans quelques minutes. Merci!"}',
        // cross_selling_group_id: "",
        // cross_selling_bots: [],
        prebooking_expiration_minutes: 15,
        // reservation_exceptions: [],
        // zone_aliases: [],
        // mixpanel_dashboard_link: "",
        // requires_email_via_whatsapp: false,
        // proposal_policy_exceptions: [],

        // // Add default language
        restaurant_language: defaultLanguages[config?.default_language],
        // // Add default country depending on default language
        country: parseCountryCode(),
        // Add reservations policies
      },
      backend: {
        name: getBackendName(),
        user: getBackendUser(),
        // password: "Q0RU9QEXj9pYbFYzWEBO",
        time_zone: "Europe/Madrid",
        max_diners: 100,
        duration: 105,
        custom_params: [],
        // cross_selling: "",
        // partner: 0,
        google_maps_place_id: config?.google?.place_id ? config?.google?.place_id : "",
        // crm_id: "",
      },
      ID: botID,
      owner: config.user.permissions[0].customer_id,
      ivr:
        config?.language_detection != "prefix" && config?.available_languages.length > 1
          ? {
              enabled: {
                value: true,
              },
              steps: [
                {
                  name: "Language",
                  type: "Mixed",
                  prompt: "Audio",
                  promptAudioURL: await getAudioFromLanguagesUrl(),
                  pre_prompt_pause: 1,
                  timeout: 10,
                  options: setLangaugesOptionsForIVR(),
                  promptSynthJSON: `{"es-ES":"<break time=\\\"1s\\\" /> Bienvenido a ${config?.name}! Llama para una reserva? modificar o gestionar una existente? o quiere hablar con el restaurante.","en-US":"<break time=\\\"1s\\\" /> Welcome to ${config?.name}! Do you want to make a new reservation? modify or cancel an existing one? o do you want to speak with the restaurant","ca-ES":"<break time=\\\"1s\\\" /> Benvingut a ${config?.name}! Truca per fer una reserva? modificar o gestionar una existent? o vols parlar amb el restaurant.","de-DE":"<break time=\\\"1s\\\" /> Willkommen bei ${config?.name}! Anrufen für eine Reservierung? eine vorhandene ändern oder verwalten? oder möchten Sie mit dem Restaurant sprechen.","fr-FR":"<break time=\\\"1s\\\" /> Bienvenue chez ${config?.name}! Appelez pour une réservation? modifier ou gérer une existante? ou souhaitez-vous parler avec le restaurant."}`,
                },
              ],
              custom_greeting: "",
              white_list: [],
              black_list: [],
            }
          : {
              black_list: [],
              custom_greeting: "",
              enabled: {
                value: false,
              },
              steps: [],
              white_list: [],
            },

      ddi: "",
      bot_type: "RESTAURANT",
      default_language: "SPANISH",
      integrations: [],
      timezone: "Europe/Madrid",
    },
  };

  function getNeed() {
    if (config?.need === "wannaTry") return "Quiere ver como funciona y valorar si le encaja";
    else if (config?.need === "needNow") return "Lo necesita cuanto antes";
    else if (config?.need === "later") {
      return "Lo quiere para mas adelante" + (config?.want_for_later ? " - " + config.want_for_later : "");
    }
    return "";
  }
  function getBookingManager(): string {
    if (config?.booking_manager !== "other" && config?.booking_manager !== undefined) return config?.booking_manager;
    return config?.other_booking_manager;
  }

  function getTelToUse(): string {
    if (config?.tel_to_use === "new") return "Nuevo".concat(config?.new_tel_prefix ? " - con prefijo " + config.new_tel_prefix : "");
    else if (config?.tel_to_use === "other") return "Propio".concat(config?.other_tel ? " - ".concat(config.other_tel) : "");
    else if (config?.tel_to_use === "google") return "Propio".concat(config?.google?.formatted_phone_number ? " - ".concat(config?.google?.formatted_phone_number) : "");
    return "";
  }

  function getCentralita(): string {
    if (config?.has_centralita === "yes") {
      if (config?.centralita_provider_custom) return "Si - ".concat(config.centralita_provider_custom);
      return "Si - " + config?.centralita_provider;
    } else if (config?.has_centralita === "no") {
      if (config?.tel_provider && config?.tel_provider !== "") {
        if (config?.tel_provider === "movistar" || config?.tel_provider === "vodafone" || config?.tel_provider === "orange") {
          return "No - " + (config?.tel_provider + " (prov. de telefono)");
        } else if (config?.tel_provider === "idk") {
          return "No, y no sabe el prov. de teléfono";
        } else {
          return "No - ".concat(config?.tel_provider_custom).concat(" (prov. de telefono)");
        }
      }
      return "No";
    } else if (config?.has_centralita === "idk") {
      if (config?.tel_provider && config?.tel_provider !== "") {
        if (config?.tel_provider === "movistar" || config?.tel_provider === "vodafone" || config?.tel_provider === "orange") {
          return "No - " + (config?.tel_provider + "(prov. de telefono)");
        } else if (config?.tel_provider === "idk") {
          return "No, y no sabe el prov. de teléfono";
        } else {
          return "No - ".concat(config?.tel_provider_custom).concat(" (prov. de telefono)");
        }
      }
      return "No lo sabe";
    }

    return "";
  }
  function getWhenAnswer(value: any): string {
    if (value === "always") return "Siempre";
    else if (value === "when-they-not-answer") return "Si no contestan";
    else if (value === "scheduled") return "En franjas horarias";
    else if (value === "never") return "Nunca";
    return "";
  }
  function getAssistantName(): string {
    if (config?.assistant_name_type === "default") return config?.assistant_name;
    else if (config?.assistant_name_type === "no_name") return "Sin nombre";
    else if (config?.assistant_name_type === "custom") return config?.assistant_name_custom;
    return "Ariel";
  }
  function getZones(): string {
    if (config?.restaurant_zones === "yes") return "Si, " + config?.other_restaurant_zones;
    else if (config?.restaurant_zones === "no") return "No";
    return "";
  }
  function getSameShift(): string {
    if (config?.same_shift === "transfer_restaurant") return "Transferir al restaurante";
    else if (config?.same_shift === "allowed") return "Permitidas, se tomará la reserva para el mismo turno";
    else if (config?.same_shift === "not_allowed_go_to_restaurant") return "No permitidas, se le indica al cliente que vaya al restaurante presencialmente";
    else if (config?.same_shift === "not_allowed_custom") return "No permitidas" + (config?.same_shift_custom ? " - Mensaje personalizado: " + config.same_shift_custom : "");
    return "";
  }
  function getShiftDuration(): string {
    if (config?.shift_duration_policy === "always") return "Siempre" + (config?.reservation_duration ? " - Tiempo: " + config?.reservation_duration : "");
    else if (config?.shift_duration_policy === "never") return "Nunca";
    else if (config?.shift_duration_policy === "custom") return config?.shift_duration_policy_custom;
    else if (config?.shift_duration_policy === "same_as_booking_manager") return "Tal como está en el libro de reservas";
    return "";
  }

  function getReservationHold(): string {
    if (config?.say_reservation_hold_policy === "yes") return "Si" + (config?.reservation_hold_policy ? " - Tiempo: " + config?.reservation_hold_policy : "");
    else if (config?.say_reservation_hold_policy === "no") return "No";
    return "";
  }
  function getTimeLimitation(): string {
    if (config?.time_limitation === "yes") return "Si" + (config?.time_limitation_value ? " - " + config?.time_limitation_value : "");
    else if (config?.time_limitation === "no") return "No";
    return "";
  }
  function getNoAvTransfer(): string {
    if (config?.no_availability_transfer === "no") return "No transferir";
    if (config?.no_availability_transfer === "open") return "Si, dentro del horario de apertura del restaurante";
    if (config?.no_availability_transfer === "handover") return "Si, en las franjas horarias de handover";
    if (config?.no_availability_transfer === "custom") return `Si, en éstas franjas horarias: ${convertShifts(config?.no_availability_transfer_schedule)} `;
    return "";
  }

  function converSaluteVoice(e: string) {
    switch (e) {
      case "1":
        return `Bienvenido a ${config.name}, en qué te puedo ayudar?`;

      case "2":
        return `Bienvenido a ${config?.name}, quiere hacer una reserva o hablar con el restaurante?`;

      case "3":
        return `Hola soy ${getAssistantName()}, ${config?.assistant_gender === "f" ? "la" : "el"} asistente de ${config?.name}. En que te puedo ayudar?`;

      case "4":
        return config?.assistant_salute_voice_custom;

      case "5":
        return `Gracias por llamar a ${config?.name ? config.name : "RESTAURANT_NAME"}. Pulsa 1 si quieres hacer una reserva, pulsa 2 si quieres cancelar o modificar una reserva existente, pulsa 3 si llamas por otras consultas.`;

      case "6":
        return `Bienvenido a ${config.name}! Llama para una reserva? modificar o gestionar una existente? o por otra consulta.`;

      default:
        return "";
    }
  }
  function converSaluteWhatsapp(e: string) {
    switch (e) {
      case "1":
        return `Bienvenido a ${config.name}, en qué te puedo ayudar?`;

      case "2":
        return `Bienvenido a ${config?.name}, quiere hacer una reserva o hablar con el restaurante?`;

      case "3":
        return `Hola soy ${getAssistantName()}, ${config?.assistant_gender === "f" ? "la" : "el"} asistente de ${config?.name}. En que te puedo ayudar?`;

      case "4":
        return config?.assistant_salute_voice_custom;

      default:
        return "";
    }
  }

  // esta funcion es como la de converSaluteVoice() pero con el SSML , para meterla en el bot directo

  async function salute(e: string) {
    switch (e) {
      case "1":
        return `{\"es-ES\":\"Bienvenido a ${config.name}, en qué te puedo ayudar?\",\"en-US\":\"Welcome to ${config.name}, how can I assist you?\", \"ca-ES\":\"Benvingut a ${config.name}, com puc ajudar-te?\", \"fr-FR\":\"Bienvenue sur ${config.name}, comment puis-je vous aider?\", \"de-DE\":\"Willkommen bei ${config.name}, wie kann ich Ihnen helfen?\"}`;

      case "2":
        return `{\"es-ES\":\"Bienvenido a ${config?.name}, quiere hacer una reserva o hablar con el restaurante?\",\"en-US\":\"Welcome to ${config?.name}, would you like to make a reservation or speak with the restaurant?\", \"ca-ES\":\"Benvingut a ${config?.name}, vols fer una reserva o parlar amb el restaurant?\", \"fr-FR\":\"Bienvenue sur ${config?.name}, souhaitez-vous faire une réservation ou parler avec le restaurant?\", \"de-DE\":\"Willkommen bei ${config?.name}, möchten Sie eine Reservierung vornehmen oder mit dem Restaurant sprechen?\"}`;

      case "3":
        return `{\"es-ES\":\"Hola soy ${getAssistantName()}, ${config?.assistant_gender === "f" ? "la" : "el"} asistente de ${config?.name}. En que te puedo ayudar?\",\"en-US\":\"Hello, I'm ${getAssistantName()}, ${
          config?.assistant_gender === "f" ? "the" : "the"
        } assistant of ${config?.name}. How can I assist you?\", \"ca-ES\":\"Hola sóc ${getAssistantName()}, ${config?.assistant_gender === "f" ? "la" : "l"}'assistent de ${
          config?.name
        }. Com puc ajudar-te?\", \"fr-FR\":\"Bonjour, je suis ${getAssistantName()}, ${config?.assistant_gender === "f" ? "l'" : "l"}'assistant(e) de ${config?.name}. Comment puis-je vous aider?\", \"de-DE\":\"Hallo, ich bin ${getAssistantName()}, ${
          config?.assistant_gender === "f" ? "die" : "der"
        } Assistent von ${config?.name}. Wie kann ich Ihnen helfen?\"}`;

      case "4":
        const customText = await handleTranslation(config?.assistant_salute_voice_custom);
        return `{\"es-ES\":\"${config?.assistant_salute_voice_custom}\",\"en-US\":\"${customText.en}\", \"ca-ES\":\"${customText.ca}\", \"fr-FR\":\"${customText.fr}\", \"de-DE\":\"${customText.de}\"}`;

      case "5":
        return `{\"es-ES\":\"Gracias por llamar a ${
          config?.name ? config.name : "RESTAURANT_NAME"
        }. Pulsa 1 si quieres hacer una reserva, pulsa 2 si quieres cancelar o modificar una reserva existente, pulsa 3 si llamas por otras consultas.\",\"en-US\":\"Thank you for calling ${
          config?.name ? config.name : "RESTAURANT_NAME"
        }. Press 1 if you want to make a reservation, press 2 if you want to cancel or modify an existing reservation, press 3 if you are calling for other inquiries.\", \"ca-ES\":\"Gràcies per trucar a ${
          config?.name ? config.name : "RESTAURANT_NAME"
        }. Prem 1 si vols fer una reserva, prem 2 si vols cancel·lar o modificar una reserva existent, prem 3 si truques per altres consultes.\", \"fr-FR\":\"Merci d'appeler ${
          config?.name ? config.name : "RESTAURANT_NAME"
        }. Appuyez sur 1 si vous souhaitez faire une réservation, appuyez sur 2 si vous souhaitez annuler ou modifier une réservation existante, appuyez sur 3 si vous appelez pour d'autres demandes.\", \"de-DE\":\"Vielen Dank für Ihren Anruf bei ${
          config?.name ? config.name : "RESTAURANT_NAME"
        }. Drücken Sie die 1, um eine Reservierung vorzunehmen, drücken Sie die 2, um eine bestehende Reservierung zu stornieren oder zu ändern, drücken Sie die 3, wenn Sie wegen anderer Anfragen anrufen.\"}`;

      case "6":
        return `{\"es-ES\":\"Bienvenido a ${config?.name}! Llama para una reserva? modificar o gestionar una existente? o por otra consulta.\",\"en-US\":\"Welcome to ${config?.name}! Are you calling to make a reservation? Modify or manage an existing one? or for any other inquiry\", \"ca-ES\":\"Benvingut a ${config?.name}! Truca per fer una reserva? modificar o gestionar-ne una existent? o per una altra consulta.\", \"fr-FR\":\"Bienvenue sur ${config?.name}! Appelez pour faire une réservation ? modifier ou gérer une réservation existante ? ou pour toute autre question.\", \"de-DE\":\"Willkommen bei ${config?.name}! Rufen Sie an, um eine Reservierung vorzunehmen? Eine bestehende Reservierung ändern oder verwalten? Oder haben Sie eine andere Frage.\"}`;

      default:
        return `{\"es-ES\":\"saludo personalizado\",\"en-US\":\"Thank you  saludo personalizado\", \"ca-ES\":\"Moltes gracies  saludo personalizado\", \"fr-FR\":\"Merci beacup saludo personalizado\", \"de-DE\":\"Gutten tak  saludo personalizado\"}`;
    }
  }

  function convertShifts(obj: any) {
    let result = "";
    if (obj) {
      const days = Object.keys(obj);

      days.forEach((day, index) => {
        const shifts = obj[day]?.shifts?.map((shift: any) => `${shift?.start_time} to ${shift?.end_time}`).join(", ");
        result += `${day}: ${shifts}`;

        if (index !== days?.length - 1) {
          result += ", ";
        }
      });

      // console.log("schedule converted", result);
    }

    return result;
  }
  function getNotificationsChannel() {
    let notiChan = "";
    notiChan = JSON.stringify(config?.notifications_channel);
    if (config?.notifications_channel_whatsapp_value) {
      notiChan += `, whatsapp phone: ${config?.notifications_channel_whatsapp_value}`;
    }
    if (config?.notifications_channel_mail_value) {
      notiChan += `, emails: ${config?.notifications_channel_mail_value}`;
    }

    return notiChan;
  }

  const payload = {
    template_id: "d-77c6c48407354830b51c36088029c84c",
    dynamic_template_data: {
      address: config?.address,
      name: config?.name,
      need: config?.advanced ? getNeed() : "",
      booking_manager: getBookingManager(),
      assistant_type: config?.advanced ? config?.assistant_type : "",
      tel_to_use: config?.advanced ? getTelToUse() : "",
      has_centralita: getCentralita(),
      when_answer: getWhenAnswer(config?.when_answer),
      when_answer_schedule: config?.when_answer === "scheduled" ? convertShifts(config?.when_answer_schedule?.days) : "",
      when_handover: getWhenAnswer(config?.when_handover),
      when_handover_schedule: config?.when_handover === "scheduled" ? convertShifts(config?.when_handover_schedule?.days) : "",
      handover_phone: config?.handover_phone ?? "Hablar con el restaurante para conseguir una segunda línea",
      same_number: config?.advanced ? (config?.same_number === "yes" ? "Si" : "No") : "",
      available_languages: config?.available_languages ? JSON.stringify(config.available_languages) : "",
      default_language: config?.default_language,
      language_detection: config?.language_detection === "prefix" ? "Por prefijo" : "Diciéndole al asistente el idioma",
      assistant_gender: config?.advanced ? (config?.assistant_gender === "m" ? "Masculino" : "Femenino") : "",
      voice_name: config?.voice_name,
      assistant_personality: config?.advanced ? (config?.assistant_personality ? JSON.stringify(config.assistant_personality) : "") : "",
      assistant_name: config?.advanced ? getAssistantName() : "",
      assistant_tuteation: config?.assistant_tuteation,
      assistant_salute_voice: config?.advanced ? converSaluteVoice(config?.assistant_salute_voice) : "",
      // assistant_salute_whatsapp: converSaluteWhatsapp(config?.assistant_salute_whatsapp),
      restaurant_zones: getZones(),
      same_shift: config?.advanced ? getSameShift() : "",
      current_shift_policy_schedule: config?.advanced ? (config?.current_shift_policy_schedule ? convertShifts(config?.current_shift_policy_schedule?.days) : "") : "",
      shift_duration_policy: config?.advanced ? getShiftDuration() : "",
      say_reservation_hold_policy: getReservationHold(),
      time_limitation: config?.advanced ? getTimeLimitation() : "",
      say_weather: config?.advanced ? (config?.say_weather === "yes" ? "Si" : "No") : "",
      notifications_channel: getNotificationsChannel(),
      send_confirmation: config?.send_confirmation === "whatsapp-free" ? "Si, whatsapp CON publicidad" : config?.send_confirmation === "whatsapp-paid" ? "Si, whatsapp SIN publicidad" : config?.send_confirmation === "sms" ? "Si, con SMS" : "No",
      reservation_policy: config?.reservation_policy
        ? JSON.stringify(
            config?.reservation_policy
              ?.map((pol: any) => {
                if (pol?.checked) {
                  return pol.name;
                }
                return "";
              })
              .filter((el: any) => el !== "")
          )
        : "",
      no_availability_transfer: getNoAvTransfer(),
      no_availability_transfer_message: config?.no_availability_transfer_message,
      no_availability_no_transfer_message: config?.no_availability_no_transfer_message,
      extra_questions_data: config?.extra_questions_data
        ? JSON.stringify(
            config.extra_questions_data.map((quest: any) => convertQuestion(quest)),
            null,
            2
          )
        : "",
      extra_question_custom: config?.extra_question_custom,
      final_comment_value: config?.final_comment_value,
      user_name: config?.user?.name,
      user_phone: config?.user?.phone?.e164,
      user_mail: config?.user?.email,
      user_id: config?.user?.id,
      customer_id: config?.user?.permissions[0]?.customer_id,
      newsletter: config?.newsletter === "yes" ? "Si" : "No",
      other_restaurants: config?.other_restaurants,
      google: config?.google ? JSON.stringify(config.google, null, 2) : "",
      legal_name: config?.legal_name,
      legal_cif: config?.legal_cif,
      legal_partner: config?.legal_partner,
      legal_partner_cif: config?.legal_partner_cif,
      legal_email: config?.legal_email,
      legal_address: config?.legal_address,
      legal_city: config?.legal_city,
      bot_url: "https://admin" + (process.env.REACT_APP_ENVIRONMENT === "development" ? ".dev" : "") + ".bookline.io/restaurants/" + bot.id,
      customer_url: "https://admin" + (process.env.REACT_APP_ENVIRONMENT === "development" ? ".dev" : "") + ".bookline.io/papi-customers/" + bot.owner_id,
      user_url: "https://admin" + (process.env.REACT_APP_ENVIRONMENT === "development" ? ".dev" : "") + ".bookline.io/papi-users/" + config?.user?.id,
    },
    from_email: "no-reply@bookline.io",
    to_emails: process.env.REACT_APP_ENVIRONMENT === "development" ? ["pedro@bookline.io", "diana@bookline.io", "dani.milan@bookline.io"] : ["comercial@bookline.io", "joan@bookline.io", "diana@bookline.io", "lydia@bookline.io"],
  };

  const responseVenue = await createNewVenue();
  if (responseVenue) {
    API.post("/bots", bot)
      .then((res1) => {
        Sentry.captureEvent({
          message: "New Bot created",
          level: "info", // Set the appropriate level for your event (info, warning, error)
          extra: {
            //  data to include with the event
            customProperty: res1,
          },
        });

        // Por ahora lo quito, ya que los ClIENT no pueden asignar numeros
        // assignNumber(res1.data);

        API.post("/emails/new", payload)
          .then((res) => {
            Sentry.captureEvent({
              message: "Email sent",
              level: "info", // Set the appropriate level for your event (info, warning, error)
              extra: {
                // Additional data to include with the event
                customProperty: res,
              },
            });
            console.log("AXIOS EMAIL", res);
            // navigateTo("/signin")
            handleFinish();
          })
          .catch((err) => {
            // navigateTo("/signin")
            handleFinish();

            Sentry.captureEvent({
              message: "Email not sent",
              level: "error", // Set the appropriate level for your event (info, warning, error)
              extra: {
                // Additional data to include with the event
                customProperty: err,
              },
            });
            console.log(err);
          });

        // setTimeout(() => {
        //  navigateTo("/signin")
        //   window.location.reload();
        // }, 500);
      })
      .catch(() => {
        const uuidUtil = uuid().slice(-4);

        let botUtil = bot;
        botUtil.id = botUtil.id + uuidUtil;

        API.post("/bots", botUtil)
          .then((res1) => {
            Sentry.captureEvent({
              message: "New Bot created",
              level: "info", // Set the appropriate level for your event (info, warning, error)
              extra: {
                // Additional data to include with the event
                customProperty: res1,
              },
            });

            API.post("/emails/new", payload)
              .then((res) => {
                Sentry.captureEvent({
                  message: "Email sent",
                  level: "info", // Set the appropriate level for your event (info, warning, error)
                  extra: {
                    // Additional data to include with the event
                    customProperty: res,
                  },
                });
                console.log("AXIOS EMAIL", res);
                // navigateTo("/signin")
                handleFinish();
              })
              .catch((err) => {
                // navigateTo("/signin")
                handleFinish();

                Sentry.captureEvent({
                  message: "Email not sent",
                  level: "error", // Set the appropriate level for your event (info, warning, error)
                  extra: {
                    // Additional data to include with the event
                    customProperty: err,
                  },
                });
                console.log(err);
              });
          })
          .catch((err) => {
            Sentry.captureEvent({
              message: "Bot not created",
              level: "error", // Set the appropriate level for your event (info, warning, error)
              extra: {
                // Additional data to include with the event
                customProperty: err,
              },
            });
            console.log(err);
          });
      });
  } else {
    console.log("Error creating Bot");
  }
}

export function sendActivateMail(config: any) {
  const payload = {
    template_id: "d-0301282268144bc8aa146fd494d77ec9",
    dynamic_template_data: {
      address: config?.address,
      name: config?.name,
      user_name: config?.user?.name,
      user_phone: config?.user?.phone?.e164,
      user_mail: config?.user?.email,
      user_id: config?.user?.id,
      customer_id: config?.user?.permissions[0]?.customer_id,
      google: config?.google ? JSON.stringify(config.google, null, 2) : "",
      legal_name: config?.legal_name,
      legal_cif: config?.legal_cif,
      legal_partner: config?.legal_partner,
      legal_partner_cif: config?.legal_partner_cif,
      legal_email: config?.legal_email,
      legal_address: config?.legal_address,
      legal_city: config?.legal_city,
      newsletter: config?.newsletter === "yes" ? "Si" : "No",
      bot_url: config?.bot_id ? "https://admin" + (process.env.REACT_APP_ENVIRONMENT === "development" ? ".dev" : "") + ".bookline.io/restaurants/" + config?.bot_id : "",
      customer_url: "https://admin" + (process.env.REACT_APP_ENVIRONMENT === "development" ? ".dev" : "") + ".bookline.io/papi-customers/" + config?.user?.permissions[0]?.customer_id,
      user_url: "https://admin" + (process.env.REACT_APP_ENVIRONMENT === "development" ? ".dev" : "") + ".bookline.io/papi-users/" + config?.user?.id,
    },
    from_email: "no-reply@bookline.io",
    to_emails: process.env.REACT_APP_ENVIRONMENT === "development" ? ["pedro@bookline.io", "diana@bookline.io"] : ["comercial@bookline.io", "joan@bookline.io", "diana@bookline.io", "lydia@bookline.io"],
  };

  API.post("/emails/new", payload)
    .then((res) => {
      Sentry.captureEvent({
        message: "Email sent",
        level: "info", // Set the appropriate level for your event (info, warning, error)
        extra: {
          // Additional data to include with the event
          customProperty: res,
        },
      });
      console.log("AXIOS EMAIL", res);
    })
    .catch((err) => {
      Sentry.captureEvent({
        message: "Email not sent",
        level: "error", // Set the appropriate level for your event (info, warning, error)
        extra: {
          // Additional data to include with the event
          customProperty: err,
        },
      });
      console.log(err);
    });
}
