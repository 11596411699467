/* eslint-disable */

import { Avatar, Checkbox, RadioChangeEvent, Row } from "antd";
import { Button, Input, Radio, Space } from "antd";

import { FC, useEffect, useState } from "react";

import "../styles.css";

import { CheckboxValueType } from "antd/es/checkbox/Group";
import femaleBlue from "../utils/avatars-svg/assistantFemaleBlueBackgroung.png";
import maleBlue from "../utils/avatars-svg/assistantMaleBlueBackgroung.png";
import avatarf0 from "../utils/avatars-svg/avatarF0.svg";
import avatarf1 from "../utils/avatars-svg/avatarF1.svg";
import avatarf2 from "../utils/avatars-svg/avatarF2.svg";
import avatarf3 from "../utils/avatars-svg/avatarF3.svg";
import avatarf4 from "../utils/avatars-svg/avatarF4.svg";
import avatarf5 from "../utils/avatars-svg/avatarF5.svg";
import avatarf6 from "../utils/avatars-svg/avatarF6.svg";
import avatarm0 from "../utils/avatars-svg/avatarM5.svg";
import avatarm1 from "../utils/avatars-svg/avatarM1.svg";
import avatarm2 from "../utils/avatars-svg/avatarM2.svg";
import avatarm3 from "../utils/avatars-svg/avatarM3.svg";
import avatarm4 from "../utils/avatars-svg/avatarM4.svg";
import avatarm5 from "../utils/avatars-svg/avatarM0.svg";
import assistantMale from "../utils/avatars-svg/assistantMale.svg";
import assistantFemale from "../utils/avatars-svg/assistantFemale.svg";
// import messi from "../utils/avatars-svg/messi.png";

import chatOptions from "../utils/chat-options2.json";
// @ts-ignore
import { catalanFlag, englishFlag, frenchFlag, germanFlag, portugueseFlag, spanishFlag, italianFlag } from "../utils/svg-utils";
import { alignItems, flexDirection } from "@doar/shared";
import { useTranslation } from "react-i18next";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  prefill?: any;
  handleNext?: any;
  handleBack?: any;
  handleChange?: any;
}

function deepCopyByPedrazo(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export const RestPersonality0: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState([]);
  const [langValue, setLangValue] = useState();
  const [selectedCatalan, setSelectedCatalan] = useState(false);
  const [prefixDetected, setPrefixDetected] = useState(false);
  const { t } = useTranslation();

  const onChange = (checkedValues: CheckboxValueType[]) => {
    // If only one language selected, set it as default language
    if (checkedValues.length < 2) {
      const configUtil = deepCopyByPedrazo(prefill);
      configUtil["default_language"] = checkedValues[0] ?? "";
      configUtil["available_languages"] = checkedValues;
      handleChange(null, configUtil);
    } else {
      handleChange("available_languages", checkedValues);
    }
  };
  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("language_detection", e.target.value);
  };

  // const plainOptions = ["Español", "Catalan", "Inglés", "Francés", "Portugués", "Alemán"];
  useEffect(() => {
    if(prefill?.available_languages.includes('catalan')){
      setSelectedCatalan(true);
    } else {
      setSelectedCatalan(false);
    }
    if (prefill?.language_detection === "prefix") {
      setPrefixDetected(true);
    } else {
      setPrefixDetected(false);
    }
    setValue(prefill?.available_languages);
    setLangValue(prefill?.language_detection);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">
        {t("assistant_title")}
        {/* Ahora configuremos tu asistente, elige los idiomas que quieres que hable tu asistente */}
      </h2>
      <p className="pUnderTitle">
        {t("assistant_title2")}
        {/* Podrás seleccionar el idioma predeterminado más adelante  */}
      </p>
      <div className="checkBoxLang">
        <Checkbox.Group
          className="checkBoxGroup"
          style={{ display: "flex", alignItems: "center", fontSize: "16px" }}
          //  options={plainOptions}
          value={value}
          onChange={onChange}
        >
          <Space direction="vertical">
            <Checkbox className="langItemCheck" value="spanish">
              <div className="langItem">
                {spanishFlag}
                <div>{t("spanish")}</div>
              </div>
            </Checkbox>
            <Checkbox className="langItemCheck" value="english">
              <div className="langItem">
                {englishFlag}

                <div>{t("english")}</div>
              </div>
            </Checkbox>
            <Checkbox className="langItemCheck" value="catalan">
              <div className="langItem">
                {catalanFlag}

                <div>{t("catalan")}</div>
              </div>
            </Checkbox>
            <Checkbox className="langItemCheck" value="french">
              <div className="langItem">
                {frenchFlag}

                <div>{t("french")}</div>
              </div>
            </Checkbox>
            <Checkbox className="langItemCheck" value="portuguese">
              <div className="langItem">
                {portugueseFlag}

                <div>{t("portuguese")}</div>
              </div>
            </Checkbox>
            <Checkbox className="langItemCheck" value="german">
              <div className="langItem">
                {germanFlag}

                <div>{t("german")}</div>
              </div>
            </Checkbox>
            <Checkbox className="langItemCheck" value="italian">
              <div className="langItem">
                {italianFlag}

                <div>{t("italian")}</div>
              </div>
            </Checkbox>
          </Space>
          {prefill?.available_languages?.length > 1 && (
            <div style={{ margin: "10px 50px 20px 200px " }}>
              <p style={{ textAlign: "left", fontSize: "1.1em", fontWeight: 600 }} className="displayNone">
                {t("lang_detection")}
                {/* ¿Cómo quiere que se haga la detección del idioma? */}
              </p>
              <Radio.Group onChange={onChangeRadio} value={langValue}>
                <Space direction="vertical">
                  <Radio style={{ margin: "10px 0", fontWeight: 400, fontSize: "18px" }} value="prefix" className="displayNone">
                    {t("by_prefix")}
                    {/* Por prefijo telefónico del cliente */}
                  </Radio>
                  <Radio style={{ margin: "10px 0", fontWeight: 400, fontSize: "18px" }} value="asking_assistant" className="displayNone">
                    {t("by_command")}
                    {/* Diciéndole explícitamente al asistente que quiere hablar en determinado idioma */}
                  </Radio>
                </Space>
              </Radio.Group>
              {selectedCatalan && prefixDetected && <p style={{ backgroundColor: "#eff1fc", borderRadius: "8px", padding: "8px" }}>{t("catalan_prefix_text")}</p>}
            </div>
          )}
        </Checkbox.Group>
      </div>
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.available_languages} onClick={() => handleNext("rest-personality-0", null)}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const RestPersonality1: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("assistant_gender", e.target.value);
  };
  useEffect(() => {
    setValue(prefill?.assistant_gender);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">¿Que género prefieres para tu asistente?</h2>

      <Radio.Group className="radioGroupStyle" onChange={onChangeRadio} value={value}>
        <Space direction="vertical">
          <Radio value="f">Femenino</Radio>
          <Radio value="m">Masculino</Radio>
          {/* <Radio value="b">No binarie</Radio> */}
        </Space>
      </Radio.Group>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.assistant_gender} onClick={() => handleNext("rest-personality-1", "rest-personality-2")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestPersonality2: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("default_language", e.target.value);
  };
  useEffect(() => {
    setValue(prefill?.default_language);
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">
        {t("default_lang")}
        {/* Elige el idioma por defecto de tu asistente */}
      </h2>
      <p className="pUnderTitle">
        {t("default_lang2")}
        {/* Para este idioma podrás configurar el tipo de voz */}
      </p>
      <div className="defaultLang">
        <Radio.Group
          className="radioGroupStyle"
          //  options={plainOptions}
          value={value}
          onChange={onChangeRadio}
        >
          <Space direction="vertical">
            {prefill?.available_languages?.includes("spanish") && (
              <Radio className="langItemCheck1" value="spanish">
                <div className="langItem">
                  {spanishFlag}
                  <div> {t("spanish")}</div>
                </div>
              </Radio>
            )}
            {prefill?.available_languages?.includes("catalan") && (
              <Radio className="langItemCheck1" value="catalan">
                <div className="langItem">
                  {catalanFlag}

                  <div>{t("catalan")}</div>
                </div>
              </Radio>
            )}
            {prefill?.available_languages?.includes("english") && (
              <Radio className="langItemCheck1" value="english">
                <div className="langItem">
                  {englishFlag}

                  <div>{t("english")}</div>
                </div>
              </Radio>
            )}
            {prefill?.available_languages?.includes("french") && (
              <Radio className="langItemCheck1" value="french">
                <div className="langItem">
                  {frenchFlag}

                  <div>{t("french")}</div>
                </div>
              </Radio>
            )}
            {prefill?.available_languages?.includes("portuguese") && (
              <Radio className="langItemCheck1" value="portuguese">
                <div className="langItem">
                  {portugueseFlag}

                  <div>{t("portuguese")}</div>
                </div>
              </Radio>
            )}
            {prefill?.available_languages?.includes("german") && (
              <Radio className="langItemCheck1" value="german">
                <div className="langItem">
                  {germanFlag}

                  <div>{t("german")}</div>
                </div>
              </Radio>
            )}
            {prefill?.available_languages?.includes("italian") && (
              <Radio className="langItemCheck1" value="italian">
                <div className="langItem">
                  {italianFlag}
                  <div>{t("italian")}</div>
                </div>
              </Radio>
            )}
          </Space>
        </Radio.Group>
      </div>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.default_language} onClick={() => handleNext("rest-personality-2", null)}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const RestPersonality3: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("voice_name", e.target.value);
    // setTimeout(() => {
    //   handleNext("rest-personality-3", "rest-personality-4");
    // }, 700);
  };
  useEffect(() => {
    if (!prefill?.voice_name && prefill?.default_language === "spanish") {
      handleChange("voice_name", "es-ES-Lucia");
      setValue("es-ES-Lucia");
    } else {
      setValue(prefill?.voice_name);
    }
  }, [prefill]);

  return (
    <div className="contentContainer">
      <h2 className="businessH2">
        {t("assistant_voice")}
        {/* Elige la voz de tu asistente */}
      </h2>
      <p className="pUnderTitle">
        {t("assistant_voice2")}
        {/* Personaliza la experiencia auditiva para tus clientes */}
      </p>
      <div>
        <Radio.Group className="voicesSelection " onChange={onChangeRadio} value={value}>
          {prefill?.default_language === "spanish" && (
            <div>
              <Space style={{ display: "flex", maxWidth: "1200px" }} className="voicesContainer" size={[16, 16]} wrap>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-Lucia" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>María</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421153019470.mp3" />
                    </div>
                  </div>
                </div>

                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-Sergio" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm0} alt="avatar" /> <p>Alejandro</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421153249575.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            </div>
          )}
          {prefill?.default_language === "catalan" && (
            <div>
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="ca-ES-Arlet" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Meritxell</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421153828870.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="ca-ES-EnricNeural" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Marc</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src=" https://storage.googleapis.com/bookline-rec/enricNeural.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            </div>
          )}
          {prefill?.default_language === "german" && (
            <div>
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="de-DE-Vicki" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Lena</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421153019470.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="de-DE-Daniel" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Hans</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="" />
                    </div>
                  </div>
                </div>
              </Space>
            </div>
          )}
          {prefill?.default_language === "portuguese" && (
            <div>
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="pt-PT-Ines" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Adriana</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421162505813.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="pt-PT-Cristiano" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Fabio</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421162646836.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            </div>
          )}
          {prefill?.default_language === "english" && (
            <div>
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="en-US-Joanna" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Casey</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://firebasestorage.googleapis.com/v0/b/t-hull-244019.appspot.com/o/alvaro%40bookline.io%2Faudio-1683045982238.mp3?alt=media" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="en-US-Matthew" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Peter</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://firebasestorage.googleapis.com/v0/b/t-hull-244019.appspot.com/o/alvaro%40bookline.io%2Faudio-1683046066342.mp3?alt=media" />
                    </div>
                  </div>
                </div>
              </Space>
            </div>
          )}
          {prefill?.default_language === "french" && (
            <div>
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="fr-FR-Lea" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Amélie</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421161746068.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="fr-FR-Remi" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Florent</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421161859263.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            </div>
          )}
          {prefill?.default_language === "italian" && (
            <div>
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="it-IT-Bianca" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Bianca</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/web-fun-audios-pro/audio-1716371629719.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="it-IT-Adriano" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Adriano</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/web-fun-audios-pro/audio-1716371668324.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            </div>
          )}
        </Radio.Group>
      </div>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.voice_name} onClick={() => handleNext("rest-personality-3", "rest-conversation-0")}>
            {t("next")}{" "}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const RestPersonality3Advanced: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");

  const onChangeRadio = (e: RadioChangeEvent) => {
    handleChange("voice_name", e.target.value);
    // setTimeout(() => {
    //   handleNext("rest-personality-3", "rest-personality-4");
    // }, 700);
  };
  useEffect(() => {
    if (prefill?.assistant_gender === "m") {
      if (!prefill?.voice_name && prefill?.default_language === "spanish") {
        handleChange("voice_name", "es-ES-Sergio");
        setValue("es-ES-Sergio");
      } else {
        setValue(prefill?.voice_name);
      }
    } else {
      if (!prefill?.voice_name && prefill?.default_language === "spanish") {
        handleChange("voice_name", "es-ES-Lucia");
        setValue("es-ES-Lucia");
      } else {
        setValue(prefill?.voice_name);
      }
    }
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">Elige la voz de tu asistente</h2>
      <p className="pUnderTitle">Personaliza la experiencia auditiva para tus clientes</p>

      <Radio.Group className="voicesSelection " onChange={onChangeRadio} value={value}>
        {prefill?.default_language === "spanish" && (
          <div>
            {prefill?.assistant_gender === "m" ? (
              <Space style={{ display: "flex", justifyContent: "center", maxWidth: "1200px" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-Sergio" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm0} alt="avatar" /> <p>Alejandro</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421153249575.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-Neural2-F" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Pedro</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/Neural2-F.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-Wavenet-B" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm2} alt="avatar" /> <p>Javier</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/wavenet-B.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-Neural2-B" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm3} alt="avatar" /> <p>Juan</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/Neural2-B.mp3" />
                    </div>
                  </div>
                </div>
                {/* <div className="cardContainer">
                  <Radio className="radioCardCircle" value="lio" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <div className="messi">
                        <img width={70} height={70} src={messi} alt="avatar" /> <p>Lionel</p>
                      </div>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://firebasestorage.googleapis.com/v0/b/bookline-dev.appspot.com/o/pedro%40bookline.io%2Fvocodes_0c9f48cd-75ad-4884-a746-50e855fa3636.mp3?alt=media&token=a97202c9-8e15-4bd4-a546-c7ea1dae4e03" />
                    </div>
                  </div>
                </div> */}
              </Space>
            ) : (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-Lucia" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>María</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421153019470.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-LaiaNeural" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf1} alt="avatar" /> <p>Lola</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/laiaNeural.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-Neural2-A" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf2} alt="avatar" /> <p>Carmen</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/Neural2-A.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="es-ES-Neural2-D" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf3} alt="avatar" /> <p>Clara</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/Neural2-D.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            )}
          </div>
        )}
        {prefill?.default_language === "catalan" && (
          <div>
            {prefill?.assistant_gender === "m" ? (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="ca-ES-EnricNeural" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Marc</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src=" https://storage.googleapis.com/bookline-rec/enricNeural.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            ) : (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="ca-ES-Arlet" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Meritxell</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421153828870.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            )}
          </div>
        )}
        {prefill?.default_language === "german" && (
          <div>
            {prefill?.assistant_gender === "m" ? (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="de-DE-Daniel" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Hans</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="" />
                    </div>
                  </div>
                </div>
              </Space>
            ) : (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="de-DE-Vicki" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Lena</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421153019470.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            )}
          </div>
        )}
        {prefill?.default_language === "portuguese" && (
          <div>
            {prefill?.assistant_gender === "m" ? (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="pt-PT-Cristiano" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Fabio</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421162646836.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="pt-PT-Thiago" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Paolo</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421162959868.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            ) : (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="pt-PT-Ines" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Adriana</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421162505813.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="pt-PT-Victoria" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Laura</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421163152949.mp3" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="pt-PT-Camila" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Amanda</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421163305479.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            )}
          </div>
        )}
        {prefill?.default_language === "english" && (
          <div>
            {prefill?.assistant_gender === "m" ? (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="en-US-Matthew" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Peter</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://firebasestorage.googleapis.com/v0/b/t-hull-244019.appspot.com/o/alvaro%40bookline.io%2Faudio-1683046066342.mp3?alt=media" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="en-US-Stephen" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm2} alt="avatar" /> <p>John</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://firebasestorage.googleapis.com/v0/b/t-hull-244019.appspot.com/o/alvaro%40bookline.io%2Faudio-1683046100029.mp3?alt=media" />
                    </div>
                  </div>
                </div>
              </Space>
            ) : (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="en-US-Joanna" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Casey</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://firebasestorage.googleapis.com/v0/b/t-hull-244019.appspot.com/o/alvaro%40bookline.io%2Faudio-1683045982238.mp3?alt=media" />
                    </div>
                  </div>
                </div>
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="en-US-Ruth" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf1} alt="avatar" /> <p>Karen</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://firebasestorage.googleapis.com/v0/b/t-hull-244019.appspot.com/o/alvaro%40bookline.io%2Faudio-1683046033749.mp3?alt=media" />
                    </div>
                  </div>
                </div>
              </Space>
            )}
          </div>
        )}
        {prefill?.default_language === "french" && (
          <div>
            {prefill?.assistant_gender === "m" ? (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="fr-FR-Remi" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarm1} alt="avatar" /> <p>Florent</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421161859263.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            ) : (
              <Space style={{ display: "flex", justifyContent: "center" }} size={[16, 16]} wrap>
                {/* <Space direction="vertical"> */}
                <div className="cardContainer">
                  <Radio className="radioCardCircle" value="fr-FR-Lea" />
                  <div className="radioCard">
                    <div className="avatarContainer">
                      <img src={avatarf0} alt="avatar" /> <p>Amélie</p>
                    </div>
                    <div className="audioContainer">
                      <audio controls src="https://storage.googleapis.com/bookline-rec/speech_20230421161746068.mp3" />
                    </div>
                  </div>
                </div>
              </Space>
            )}
          </div>
        )}
      </Radio.Group>

      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.voice_name} onClick={() => handleNext("rest-personality-3", "rest-personality-4")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RestPersonality4: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<any>([]);
  const [msg1, setMsg1] = useState("");
  const [msg2, setMsg2] = useState("");

  function handleClick(personality: string) {
    let valuesUtil: string[] = values;
    if (valuesUtil.includes(personality)) {
      valuesUtil = valuesUtil.filter((pers) => pers !== personality);
    } else if (valuesUtil.length < 2) {
      valuesUtil.push(personality);
    }

    console.log("SELECTED", valuesUtil);

    handleChange("assistant_personality", valuesUtil);
  }

  useEffect(() => {
    const jsonUtil: any = chatOptions;
    if (prefill?.assistant_personality) {
      setValues(prefill?.assistant_personality);

      const keyUtil: any = prefill.assistant_personality.join("-");
      const keyUtil1: any = prefill.assistant_personality.reverse().join("-");
      if (jsonUtil[keyUtil]) {
        setMsg1(jsonUtil[keyUtil].msg1);
        setMsg2(jsonUtil[keyUtil].msg2);
      } else if (jsonUtil[keyUtil1]) {
        setMsg1(jsonUtil[keyUtil1].msg1);
        setMsg2(jsonUtil[keyUtil1].msg2);
      }
    } else {
      handleChange("assistant_personality", ["amistoso"]);
      // setValues(["amistoso"]);
      setMsg1(jsonUtil.amistoso.msg1);
      setMsg2(jsonUtil.amistoso.msg2);
    }
  }, [prefill]);

  return (
    <div>
      <h2 className="businessH2">
        Elige la personalidad{" "}
        <span className="betaTitle" title="Ésta funcionalidad está aún en proceso de desarrollo">
          beta
        </span>
      </h2>

      <p className="pUnderTitle">Puedes combinar hasta 2 personalidades si lo deseas</p>

      {/* <Checkbox.Group options={plainOptions} defaultValue={value} onChange={onChange} /> */}
      <div className="personalitiesContainer">
        <div className="chatPanel fondoPersonality">
          <div className="msgSend">
            <div className="msgChat" style={{ maxWidth: "60%" }}>
              <div dangerouslySetInnerHTML={{ __html: msg1 }} />
            </div>
          </div>
          <div className="msgReply">
            <div className="replyMsg" style={{ maxWidth: "60%" }}>
              <div dangerouslySetInnerHTML={{ __html: "Hola! Quisiera reservar una mesa para 2 por favor." }} />
            </div>
          </div>
          <div className="msgSend">
            <div className="msgChat" style={{ maxWidth: "60%" }}>
              <div dangerouslySetInnerHTML={{ __html: msg2 }} />
            </div>
          </div>
        </div>
        <div className="buttonsPanel">
          <Row className="buttonsRow">
            <Button className={values?.includes("amistoso") ? "butSelect" : ""} onClick={() => handleClick("amistoso")}>
              Amistoso
            </Button>
            <Button className={values?.includes("elegante") ? "butSelect" : ""} onClick={() => handleClick("elegante")}>
              Elegante
            </Button>
            <Button className={values?.includes("serio") ? "butSelect" : ""} onClick={() => handleClick("serio")}>
              Serio
            </Button>
          </Row>
          <Row className="buttonsRow">
            <Button className={values?.includes("simpático") ? "butSelect" : ""} onClick={() => handleClick("simpático")}>
              Simpático
            </Button>
            <Button className={values?.includes("calmado") ? "butSelect" : ""} onClick={() => handleClick("calmado")}>
              Calmado
            </Button>
            <Button className={values?.includes("sarcástico") ? "butSelect" : ""} onClick={() => handleClick("sarcástico")}>
              Sarcástico
            </Button>
            <Button className={values?.includes("religioso") ? "butSelect" : ""} onClick={() => handleClick("religioso")}>
              Religioso
            </Button>
          </Row>
          <Row className="buttonsRow">
            <Button className={values?.includes("extravagante") ? "butSelect" : ""} onClick={() => handleClick("extravagante")}>
              Extravagante
            </Button>
            <Button className={values?.includes("entusiasta") ? "butSelect" : ""} onClick={() => handleClick("entusiasta")}>
              Entusiasta
            </Button>
            <Button className={values?.includes("gracioso") ? "butSelect" : ""} onClick={() => handleClick("gracioso")}>
              Chistoso
            </Button>
          </Row>
        </div>
      </div>
      <div className="buttonsContainer">
        <div className="backButton">
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={!prefill?.assistant_personality} onClick={() => handleNext("rest-personality-4", "rest-personality-5")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export const RestPersonality5: FC<IProps> = ({ prefill, handleNext, handleChange, handleBack }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [nameValue, setNameValue] = useState(prefill.assistant_gender === "f" ? "Clara" : "Juan");
  const [count, setCount] = useState(0);

  const maleNames = [
    "Adrian",
    "Alejandro",
    "Andres",
    "Antonio",
    "Arturo",
    "Benjamin",
    "Carlos",
    "Cesar",
    "Daniel",
    "David",
    "Diego",
    "Eduardo",
    "Emilio",
    "Enrique",
    "Esteban",
    "Federico",
    "Felipe",
    "Francisco",
    "Gabriel",
    "Gonzalo",
    "Guillermo",
    "Hector",
    "Ignacio",
    "Iker",
    "Ismael",
    "Jaime",
    "Javier",
    "Jesus",
    "Jorge",
    "Jose",
    "Juan",
    "Julian",
    "Lorenzo",
    "Luis",
    "Manuel",
    "Marco",
    "Mariano",
    "Mario",
    "Miguel",
    "Nacho",
    "Nicolás",
    "Oscar",
    "Pablo",
    "Pedro",
    "Rafael",
    "Ramon",
    "Roberto",
    "Rodrigo",
    "Santiago",
    "Sergio",
    "Victor",
  ];

  const femaleNames = [
    "Isabela",
    "Ana",
    "Carmen",
    "Elena",
    "Lucia",
    "Sofia",
    "Clara",
    "Adriana",
    "Beatriz",
    "Maria",
    "Natalia",
    "Gabriela",
    "Valeria",
    "Raquel",
    "Paula",
    "Luisa",
    "Ines",
    "Antonia",
    "Carolina",
    "Marisol",
    "Esther",
    "Patricia",
    "Rosa",
    "Lola",
    "Marta",
    "Paloma",
    "Silvia",
    "Gloria",
    "Julia",
    "Cristina",
  ];
  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log("prefill en rest pers 5", prefill);

    let configUtil = JSON.parse(JSON.stringify(prefill));
    if (e.target.value === "default") {
      configUtil["assistant_name_type"] = e.target.value;
      configUtil["assistant_name"] = nameValue;

      handleChange(null, configUtil);
    } else {
      handleChange("assistant_name_type", e.target.value);
    }

    // if (e.target.value === "no_name") {
    //   setTimeout(() => {
    //     handleNext("rest-personality-5", "rest-conversation-0");
    //   }, 700);
    // }
  };

  const handlePickName = () => {
    setValue("default");
    if (prefill.assistant_gender === "f") {
      setNameValue(femaleNames[count % 4]);
      handleChange("assistant_name", femaleNames[count % femaleNames.length]);
    } else {
      setNameValue(maleNames[count % 4]);
      handleChange("assistant_name", maleNames[count % maleNames.length]);
    }
    setCount(count + 1);
  };

  function checkDisabled() {
    if (prefill?.assistant_name_type === "default") {
      return false;
    } else if (prefill?.assistant_name_type === "no_name") {
      return false;
    } else if (prefill?.assistant_name_type === "custom" && prefill?.assistant_name_custom) {
      return false;
    }
    return true;
  }
  useEffect(() => {
    if (prefill?.assistant_name_type) {
      setValue(prefill?.assistant_name_type);
    }
    if (prefill?.assistant_name) {
      setNameValue(prefill.assistant_name);
    }
  }, [prefill]);

  return (
    <div className="contentContainer">
      <h2 className="businessH2">¿Quieres ponerle nombre a tu asistente?</h2>
      <div className="assistantDiv" style={{ display: "flex", alignItems: "flex-start", marginTop: "0px" }}>
        <Radio.Group className="radioGroupStyle chooseName" onChange={onChangeRadio} value={value}>
          <Space direction="vertical">
            <Radio value="default">
              <div>
                Tiene cara de <strong>{nameValue}</strong>
                <span className="randomizeSpan" onClick={handlePickName}>
                  sugerir otro nombre
                </span>
              </div>
            </Radio>

            <Radio value="no_name">Sin nombre, gracias</Radio>
            <Radio value="custom">Quiero elegirlo yo</Radio>
            {value === "custom" && <Input style={{ width: "100%", marginLeft: 10 }} placeholder="Escriba el nombre aqui" value={prefill?.assistant_name_custom} onChange={(e: any) => handleChange("assistant_name_custom", e.target.value)} />}
          </Space>
        </Radio.Group>
        <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "25px", marginBottom: "25px" }}>
          <img className="assistantAvatar" src={prefill?.assistant_gender === "f" ? femaleBlue : maleBlue} alt="assistant avatar" />
          {/* <img src={prefill?.assistant_gender === "m" ? assistantMale : assistantFemale} alt="assistant avatar" /> */}
        </div>
      </div>
      <div className="buttonsContainer">
        <div className="backButton">
          {/* {} <img src={femaleBlue} alt="female avatar" />
          <img src={maleBlue} alt="female avatar" /> */}
          <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
              fill="#333F64"
              stroke="#333F64"
              strokeWidth="1.15217"
            />
            <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
          </svg>
        </div>
        <div className="nextButton">
          <Button type="primary" disabled={checkDisabled()} onClick={() => handleNext("rest-personality-5", "rest-conversation-0")}>
            {t("next")}
          </Button>
        </div>
      </div>
    </div>
  );
};
