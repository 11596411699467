/* eslint-disable */

import { Checkbox, Alert, Collapse, Modal, Button, Input, Radio, Steps, Space, message, theme, Spin } from "antd";

import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "../styles.css";

import { createBot } from "../utils/create-bot";
import { collection, firestore, limit, onSnapshot, orderBy, query, where } from "src/config/firebase";
import { scrollToBottom } from "react-scroll/modules/mixins/animate-scroll";
import { Info } from "react-feather";
import accord from "../utils/example-images/accordionFake.png";
import { Spinner } from "@doar/components";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

interface IProps {
  prefill?: any;
  handleNext?: any;
  handleBack?: any;
  handleChange?: any;
  handleFinish?: any;
  handleTry?: any;
}

export const RestTryIt0: FC<IProps> = ({ prefill, handleNext, handleBack, handleTry }) => {
  const [disabled, setDisabled] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const { t } = useTranslation();

  function capitalizeFirstLetter(inputString: string) {
    if (inputString.length > 0) {
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    } else {
      return inputString; // Handle empty string
    }
  }

  function sendConfirmation(text: string) {
    switch (text) {
      case "whatsapp-free":
        return "Sí, con WhatsApp y publicidad de Bookline. (gratis)";
      case "whatsapp-paid":
        return "Sí, con Whatsapp pero sin publicidad. (10c/msg)";
      case "sms":
        return "Sí, con SMS. (10c/msg)";
      case "no":
        return "No.";
    }
  }

  function selectedLanguage(language: string) {
    switch (language) {
      case "spanish":
        return "Español";
      case "english":
        return "Inglés";
      case "catalan":
        return "Catalán";
      case "german":
        return "Alemán";
      case "french":
        return "Francés";
      case "italian":
        return "Italiano";
      case "portuguese":
        return "Portugués";
    }
  }
  function convertQuestion(e: string) {
    switch (e) {
      case "1":
        return "¿Algún comentario adicional como alergias, intolerancias o peticiones especiales que quieras indicar?";

      case "2":
        return "En caso de venir con niños y necesitar tronas, por favor especifique cuántas necesitas.";

      case "3":
        return "¿Cómo nos ha conocido?";

      case "4":
        return "¿Viene alguna persona con movilidad reducida?";
      default:
        return "";
    }
  }

  function getWhenAnswer(value: any): string {
    if (value === "always") return "Siempre";
    else if (value === "when-they-not-answer") return "Si no contestan";
    else if (value === "scheduled") return "En franjas horarias";
    else if (value === "never") return "Nunca";
    return "";
  }

  function getCentralita(): string {
    if (prefill.has_centralita === "yes") {
      if (prefill?.centralita_provider_custom) return "Sí, ".concat(prefill.centralita_provider_custom) + ".";
      return "Sí, " + prefill?.centralita_provider + ".";
    } else if (prefill?.has_centralita === "no") {
      if (prefill?.tel_provider && prefill?.tel_provider !== "") {
        if (prefill?.tel_provider === "movistar" || prefill?.tel_provider === "vodafone" || prefill?.tel_provider === "orange") {
          return "No, " + (prefill?.tel_provider + " es el proveedor de teléfono.");
        } else if (prefill?.tel_provider === "idk") {
          return "No, y no conozco el proveedor de teléfono.";
        } else {
          return "No, ".concat(prefill?.tel_provider_custom).concat(" es el proveedor de teléfono.");
        }
      }
      return "No";
    } else {
      if (prefill?.tel_provider && prefill?.tel_provider !== "") {
        if (prefill?.tel_provider === "movistar" || prefill?.tel_provider === "vodafone" || prefill?.tel_provider === "orange") {
          return "No, " + (prefill?.tel_provider + " es el proveedor de teléfono.");
        } else if (prefill?.tel_provider === "idk") {
          return "No, y no conozco el proveedor de teléfono";
        } else {
          return "No, ".concat(prefill?.tel_provider_custom).concat(" es el proveedor de teléfono.");
        }
      }
      return "No lo sabe";
    }
  }

  function getBookingManager(e: string) {
    switch (e) {
      case "cover":
        return "CoverManager";
      case "fork":
        return "The Fork";
      case "restoo":
        return "Restoo";
      case "none":
        return "No tengo libro de reservas.";
      case "other":
        return `${prefill.other_booking_manager}`;
    }
  }

  useEffect(() => {
    console.log("PREFILL >>>>>", prefill);

    if (prefill?.botNumber) {
      setDisabled(true);
    } else setDisabled(false);
  }, [prefill]);

  return (
    // <div className="contentContainer">
    //   <h2 className="businessH2">Resumen</h2>
    //   <p className="pUnderTitle">Éste es un resumen de la información que has proporcionado, verifica que todo esté correcto antes de crear tu asistente o puedes regresar y hacer los cambios que necesites.</p>
    //   <div className="accordionCont">
    //     {/* <img src={accord} alt="accordeon" /> */}
    //     <Space style={{ width: "80%" }} direction="vertical">
    //       <Collapse>
    //         <Panel className="accordeonPanel" header="Cliente" key="1">
    //           <div style={{ fontWeight: "400" }}>
    //             <div>
    //               <strong>Restaurante</strong> <br />
    //               {prefill.name}
    //             </div>
    //             <div>
    //               <strong>Dirección</strong> <br />
    //               {prefill.google.formatted_address}
    //             </div>
    //             <div>
    //               <strong>Télefono</strong> <br />
    //               {prefill.phone}
    //             </div>
    //             <div>
    //               <strong>Libro de reservas</strong> <br />
    //               {getBookingManager(prefill.booking_manager)}
    //             </div>
    //             <div>
    //               <strong>¿Quieres añadir otros restaurantes?</strong> <br />
    //               {prefill.other_restaurants !== "" ? prefill.other_restaurants : "No."}
    //             </div>
    //           </div>
    //         </Panel>
    //       </Collapse>
    //       <Collapse>
    //         <Panel className="accordeonPanel" header="Canal" key="2">
    //           <div style={{ fontWeight: "400" }}>
    //             <div>
    //               <strong>¿Tienes centralita?</strong> <br />
    //               {getCentralita()}
    //             </div>
    //             <div>
    //               <strong>¿Cuando quieres que salte el asistente?</strong> <br />
    //               {getWhenAnswer(prefill.when_answer) + "."}
    //             </div>
    //           </div>
    //         </Panel>
    //       </Collapse>
    //       <Collapse>
    //         <Panel className="accordeonPanel" header="Asistente" key="3">
    //           <div style={{ fontWeight: "400" }}>
    //             <div>
    //               <strong>Idiomas seleccionados</strong> <br />
    //               {prefill.available_languages.map((language: string, index: any) => (
    //                 <span key={index}>
    //                   {selectedLanguage(language)}
    //                   {index < prefill.available_languages.length - 2 ? ", " : index < prefill.available_languages.length - 1 ? " y " : ""}
    //                 </span>
    //               ))}
    //             </div>
    //             <div>
    //               <strong>Idioma por defecto</strong> <br />
    //               {selectedLanguage(prefill.default_language)}
    //             </div>
    //             <div>
    //               <strong>Género del asistente</strong> <br />
    //               {prefill.assistant_gender === "f" ? "Femenino" : "Masculino"}
    //             </div>
    //           </div>
    //         </Panel>
    //       </Collapse>
    //       <Collapse>
    //         <Panel className="accordeonPanel" header="Conversación" key="4">
    //           <div style={{ fontWeight: "400" }}>
    //             <div>
    //               <strong>¿Qué trato prefieres darle al cliente?</strong> <br />
    //               {capitalizeFirstLetter(prefill.assistant_tuteation)}
    //             </div>
    //           </div>
    //         </Panel>
    //       </Collapse>
    //       <Collapse>
    //         <Panel className="accordeonPanel" header="Reservas" key="5">
    //           <div style={{ fontWeight: "400" }}>
    //             <div>
    //               <strong>¿Quiéres avisar del tiempo de garantía de reserva?</strong> <br />
    //               {prefill.say_reservation_hold_policy === "yes" ? `Sí, ${prefill.reservation_hold_policy} minutos.` : "No."}
    //             </div>
    //             <div>
    //               <strong>¿Quiéres que el asistente pregunte algo más?</strong> <br />
    //               {prefill?.extra_questions_data && prefill.extra_questions_data.length > 0 && prefill.extra_question_custom === ""
    //                 ? prefill?.extra_questions_data.map((question: string, index: number) => <div key={index}>- {convertQuestion(question)}</div>)
    //                 : "No."}
    //               <br />
    //               {prefill.extra_question_custom}
    //             </div>
    //             <div>
    //               <strong>¿Quiéres enviar una confirmación de la reserva?</strong> <br />
    //               {sendConfirmation(prefill.send_confirmation)}
    //             </div>
    //             <div>
    //               <strong>¿Quiéres añadir algo más?</strong> <br />
    //               {prefill.final_comment === "yes" ? `Sí, ${prefill.final_comment_value}.` : "No."}
    //             </div>
    //           </div>
    //         </Panel>
    //       </Collapse>
    //     </Space>
    //   </div>
    //   <div className="buttonsContainer">
    //     <div className="backButton">
    //       <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path
    //           d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
    //           fill="#333F64"
    //           stroke="#333F64"
    //           strokeWidth="1.15217"
    //         />
    //         <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
    //       </svg>
    //     </div>
    //     <div className="nextButton">
    //       <Button
    //         disabled={disabled}
    //         type="primary"
    //         onClick={() => {
    //           setDisabled(true);
    //           handleTry();
    //           // handleNext("rest-tryit-0", "rest-tryit-1");
    //         }}
    //       >
    //         Crear
    //       </Button>
    //     </div>
    //     {/* <div style={{ display: prefill?.user?.role === "ADMIN" ? "block" : "none" }}>
    //         <Button
    //           disabled={!disabled}
    //           style={{ marginTop: "30px" }}
    //           type="primary"
    //           onClick={() => {
    //             handleNext("rest-tryit-0", "rest-tryit-1");
    //           }}
    //         >
    //           NEXT
    //         </Button>
    //       </div> */}
    //   </div>
    // </div>
    <div className={spinner ? "contentContainer relative" : "contentContainer"}>
      {spinner && (
        <div className="overlay">
          <div className="spinnerContainer">
            <Spinner className="spinner" size="xl" />
          </div>
        </div>
      )}
      <div className={spinner ? "content blurred" : "content"}>
        <h2 className="businessH2">{t("summary")}</h2>
        <p className="pUnderTitle">{t("summary_description")}</p>
        <div className="accordionCont">
          <Space style={{ width: "80%" }} direction="vertical">
            <Collapse>
              <Panel className="accordeonPanel" header={t("customer")} key="1">
                <div style={{ fontWeight: "400" }}>
                  <div>
                    <strong>{t("restaurant")}</strong> <br />
                    {prefill.name}
                  </div>
                  <div>
                    <strong>{t("address")}</strong> <br />
                    {prefill.google.formatted_address}
                  </div>
                  <div>
                    <strong>{t("phone")}</strong> <br />
                    {prefill.phone}
                  </div>
                  <div>
                    <strong>{t("booking_manager")}</strong> <br />
                    {getBookingManager(prefill.booking_manager)}
                  </div>
                  <div>
                    <strong>{t("add_other_restaurants")}</strong> <br />
                    {prefill.other_restaurants ? prefill.other_restaurants : t("no_specify")}
                  </div>
                </div>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel className="accordeonPanel" header={t("channel")} key="2">
                <div style={{ fontWeight: "400" }}>
                  <div>
                    <strong>{t("do_you_have_switchboard")}</strong> <br />
                    {getCentralita()}
                  </div>
                  <div>
                    <strong>{t("when_should_assistant_start")}</strong> <br />
                    {getWhenAnswer(prefill.when_answer) + "."}
                  </div>
                </div>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel className="accordeonPanel" header={t("assistant")} key="3">
                <div style={{ fontWeight: "400" }}>
                  <div>
                    <strong>{t("selected_languages")}</strong> <br />
                    {prefill.available_languages.map((language: string, index: any) => {
                      return (
                        <span key={index}>
                          {selectedLanguage(language)}
                          {index < prefill.available_languages.length - 2 ? ", " : index === prefill.available_languages.length - 2 ? ` ${t("and")} ` : ""}
                        </span>
                      );
                    })}
                  </div>
                  <div>
                    <strong>{t("default_language")}</strong> <br />
                    {selectedLanguage(prefill.default_language)}
                  </div>
                  <div>
                    <strong>{t("assistant_gender")}</strong> <br />
                    {prefill.assistant_gender === "f" ? t("female") : t("male")}
                  </div>
                </div>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel className="accordeonPanel" header={t("conversation")} key="4">
                <div style={{ fontWeight: "400" }}>
                  <div>
                    <strong>{t("formality_preference")}</strong> <br />
                    {capitalizeFirstLetter(prefill.assistant_tuteation)}
                  </div>
                </div>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel className="accordeonPanel" header={t("reservations")} key="5">
                <div style={{ fontWeight: "400" }}>
                  <div>
                    <strong>{t("notify_warranty_time")}</strong> <br />
                    {prefill.say_reservation_hold_policy === "yes" ? `${t("yes")}, ${prefill.reservation_hold_policy} ${t("minutes")}.` : t("no")}
                  </div>
                  <div>
                    <strong>{t("ask_anything_else")}</strong> <br />
                    {prefill?.extra_questions_data && prefill.extra_questions_data.length > 0 && prefill.extra_question_custom === ""
                      ? prefill?.extra_questions_data.map((question: string, index: number) => <div key={index}>- {convertQuestion(question)}</div>)
                      : t("no")}
                    <br />
                    {prefill.extra_question_custom}
                  </div>
                  <div>
                    <strong>{t("send_confirmation")}</strong> <br />
                    {sendConfirmation(prefill.send_confirmation)}
                  </div>
                  <div>
                    <strong>{t("add_anything_else")}</strong> <br />
                    {prefill.final_comment === "yes" ? `${t("yes")}, ${prefill.final_comment_value}.` : t("no")}
                  </div>
                </div>
              </Panel>
            </Collapse>
          </Space>
        </div>
        {!spinner && (
          <div className="buttonsContainer">
            <div className="backButton">
              <svg onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
                  fill="#333F64"
                  stroke="#333F64"
                  strokeWidth="1.15217"
                />
                <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
              </svg>
            </div>
            <div className="nextButton">
              <Button
                disabled={disabled}
                type="primary"
                onClick={() => {
                  setSpinner(true);
                  setDisabled(true);
                  handleTry();
                }}
              >
                {t("create")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const RestTryIt1: FC<IProps> = ({ prefill, handleFinish, handleNext }) => {
  const [disabled, setDisabled] = useState(false);
  const chatContRef = useRef<HTMLDivElement>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [allInts, setAllInts] = useState([]);
  const [botText0, setBotText0] = useState("");
  const [newPrefill, setNewPrefill] = useState("");
  const [botText1, setBotText1] = useState("");
  const [userText0, setUserText0] = useState("");
  const [userText1, setUserText1] = useState("");
  const [loading, setLoading] = useState(true);
  const [chat, setChat] = useState(true);

  function removeSSMLTags(text: any) {
    // Regular expression pattern to match SSML tags
    const ssmlRegex = /<[^>]+>/g;

    // Check if the text contains any SSML tags
    if (ssmlRegex.test(text)) {
      // Remove SSML tags from the text using regex
      const plainText = text.replace(ssmlRegex, "");
      return plainText;
    }

    // If no SSML tags found, return the text as is
    return text;
  }

  function getText(interaction: any) {
    // console.log("interaction en getText:", interaction);

    let userText = "";
    let botText = "";
    if (interaction.input.reason === "timeout") {
      userText = "-- SIN RESPUESTA --";
    } else if (interaction.input.call_status === "completed") {
      userText = "La llamada ha terminado";
    } else if (interaction.input.call_status === "trying") {
    } else if (interaction.input.user_input !== null && interaction.input.user_input) {
      userText = interaction.input.user_input;
    }
    if (interaction?.output[0]?.action_data) {
      if (Array.isArray(interaction?.output[0]?.action_data)) {
        botText = removeSSMLTags(interaction?.output[0]?.action_data[0]);
      } else {
        botText = removeSSMLTags(interaction?.output[0]?.action_data);
      }
    } else if (interaction?.output[1]?.action_data) {
      if (Array.isArray(interaction?.output[1]?.action_data)) {
        botText = removeSSMLTags(interaction?.output[1]?.action_data[0]);
      } else {
        botText = removeSSMLTags(interaction?.output[1]?.action_data);
      }
    }

    if (botText === "/jambonz-call") botText = "";

    return {
      user: userText,
      bot: botText,
    };
  }
  useEffect(() => {
    if (prefill?.botNumber) {
      setDisabled(true);
    } else setDisabled(false);
  }, [prefill]);

  function getUniqueKey() {
    const timestamp = new Date().getTime();
    const random = Math.random().toString(36).substr(2, 9);
    return `${timestamp}-${random}`;
  }

  function getAssistantName(): string {
    if (prefill?.assistant_name_type === "default") return prefill?.assistant_name;
    else if (prefill?.assistant_name_type === "no_name") return "Sin nombre";
    else if (prefill?.assistant_name_type === "custom") return prefill?.assistant_name_custom;
    return "Ariel";
  }

  useEffect(() => {
    console.log("PREFILL EN  try 1", prefill);

    // let currentIndex = 0;
    let intervalId: any;

    let intIndex = 0;
    let botText1Util = "";
    let userText1Util = "";
    let allIntsUtil: any = [];
    if (prefill?.botNumber) {
      setLoading(false);
    } else if (!prefill?.botNumber) {
      setLoading(false);
      setChat(false);
    }

    const q = query(collection(firestore, "calls"), where("destination", "==", "00" + prefill?.botNumber), orderBy("start", "desc"), limit(1));
    onSnapshot(q, (querySnapshot) => {
      // console.log("QUERTUUUU", querySnapshot);

      querySnapshot.forEach((doc) => {
        const call = doc.data();
        const ints = call.interactions;
        const last_int = ints[ints.length - 1];

        // console.log("LAST ITERACTION", last_int);
        // console.log("INDEX ", intIndex);

        if (intIndex > 1) {
          allIntsUtil.push(last_int);
          setAllInts(allIntsUtil);

          botText1Util = getText(last_int).bot;
          let botText1UtilBackup = "";
          let botText1UtilIndex = 0;
          userText1Util = getText(last_int).user;

          setBotText1("");
          const botInterval1 = setInterval(() => {
            if (botText1UtilIndex <= botText1Util.length) {
              botText1UtilBackup = botText1Util.slice(0, botText1UtilIndex);
              setBotText1(botText1UtilBackup);
              botText1UtilIndex++;
            } else {
              clearInterval(botInterval1);
            }
          }, 50);
          setUserText1(userText1Util);
        } else if (intIndex === 0) {
          let saluteText = "";

          switch (prefill?.assistant_salute_voice) {
            case "1":
              saluteText = `Bienvenido a ${prefill?.name}, en qué te puedo ayudar?`;
              break;

            case "2":
              saluteText = `Bienvenido a ${prefill?.name}, quiere hacer una reserva o hablar con el restaurante?`;
              break;

            case "3":
              saluteText = `Hola soy ${getAssistantName()}, ${prefill?.assistant_gender === "f" ? "la" : "el"} asistente de ${prefill?.name}. En que te puedo ayudar?`;
              break;

            case "4":
              saluteText = prefill?.assistant_salute_voice_custom;
              break;

            case "5":
              saluteText = `Gracias por llamar a ${prefill?.name ? prefill.name : "Restaurante Bookline"}. Pulsa 1 si quieres hacer una reserva, pulsa 2 si quieres cancelar o modificar una reserva existente, pulsa 3 si llamas por otras consultas.`;
              break;

            case "6":
              saluteText = `Bienvenido a ${prefill?.name ? prefill.name : "Restaurante Bookline"}! Llama para una reserva? Modificar o gestionar una existente? O por otra consulta.`;
              break;

            default:
              saluteText = "";
              break;
          }

          const firstMessage = { output: [{ action_data: [saluteText] }], input: { user_input: "" } };
          allIntsUtil.push(firstMessage);
          setAllInts(allIntsUtil);
        } else if (intIndex === 1) {
          allIntsUtil = [];
          allIntsUtil.push(last_int);
          setAllInts(allIntsUtil);
        }
        // }
        intIndex += 1;
        setCurrentIndex(intIndex);
      });
    });

    return () => {
      clearInterval(intervalId);
    };
  }, [prefill]);

  useEffect(() => {
    const scrollToTheBottom = () => {
      const scrollEl = chatContRef.current;
      scrollEl?.scroll({
        top: scrollEl?.scrollHeight,
        behavior: "smooth",
      });
    };
    scrollToTheBottom();
  }, [currentIndex]);

  return (
    <div style={{ height: "80%" }}>
      <div className="finalCont">
        <h2 className="titleFinal">¡Ya has creado tu asistente!</h2>

        {!chat && (
          <>
            <div className="informationDiv">
              <Info style={{ width: "30px" }} />
              <span>Hemos utilizado la información proporcionada para configurar su asistente pero todavía no está conectado con tu libro de reservas. Un agente comercial se pondrá en contacto contigo para ayudarte con los siguientes pasos.</span>
            </div>
          </>
        )}

        {/* {disabled && <p className="congrats">{prefill?.botNumber ? "+" + prefill?.botNumber : ""}</p>} */}
        {chat && (
          <div className="chatAndText">
            <div className="textContaLeft">
              <div className="textInfo">
                Puedes ver <strong>en directo</strong> cómo será la experiencia de tus clientes llamando a este número{" "}
              </div>
              <div className="phoneAndNumber">
                {loading ? (
                  //  <Spin className="spin" />
                  <Spinner className="spinner1" size="md" color="primary" />
                ) : (
                  <>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.0333 17.9833C13.4333 22.7 17.3 26.55 22.0167 28.9667L25.6833 25.3C26.1333 24.85 26.8 24.7 27.3833 24.9C29.25 25.5167 31.2667 25.85 33.3333 25.85C34.25 25.85 35 26.6 35 27.5167V33.3333C35 34.25 34.25 35 33.3333 35C17.6833 35 5 22.3167 5 6.66667C5 5.75 5.75 5 6.66667 5H12.5C13.4167 5 14.1667 5.75 14.1667 6.66667C14.1667 8.75 14.5 10.75 15.1167 12.6167C15.3 13.2 15.1667 13.85 14.7 14.3167L11.0333 17.9833Z"
                        fill="#5D82FC"
                      />
                    </svg>
                    <div className="tuvieja">
                      <div>
                        <a className="phoneNumber" style={{ color: "#5D82FC" }} href={`tel:${prefill?.botNumber}`}>
                          {prefill?.botNumber ? prefill?.botNumber.slice(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "Número no disponible"}
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="chatCont">
              <div style={{ height: "100px" }}></div>
              <div className="contenedorChats" ref={chatContRef}>
                {allInts.map((int: any, index: number) => (
                  <div key={getUniqueKey()}>
                    {index === 0 && (
                      <div className="messagesContainer">
                        <div className="btMessage">{getText(int).bot}</div>
                        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                          {allInts.length > 1 ? (
                            <div className={`${getText(int).user !== "" ? "usrMessage" : ""}`}>{getText(int).user}</div>
                          ) : (
                            <div className="usrMessage">
                              <div className="dots-container">
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* )} */}
                        {/* {index === allInts.length - 1 && botText1 !== "" && <div className="btMessage">{botText1}</div>} */}
                      </div>
                    )}
                    {index !== 0 && (
                      <div>
                        {getText(int).user !== "" && (
                          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <div className="usrMessage">{getText(int).user}</div>
                          </div>
                        )}
                        {index !== allInts.length - 1 && getText(int).bot !== "" && <div className="btMessage">{getText(int).bot}</div>}
                        {index === allInts.length - 1 && botText1?.length > 0 && <div className="btMessage">{botText1}</div>}
                      </div>
                    )}
                  </div>
                ))}
                <div style={{ height: "50px", color: "white", fontSize: "0" }}>|</div>
              </div>
              {/* <div className="fondoCont" /> */}
            </div>
          </div>
        )}
      </div>
      <div className="buttonsDivFinal">
        <div className="activarButton">
          <Button
            disabled={!disabled}
            style={{ marginTop: "30px" }}
            type="primary"
            onClick={() => {
              handleNext("rest-tryit-1", "rest-legal-0");
            }}
          >
            Lo quiero activar ya
          </Button>
        </div>
        <div className="finalizarButton">
          <Button
            type="primary"
            disabled={!disabled}
            // style={{ marginTop: "30px", backgroundColor: "#E7E7E7", fontWeight: "normal" }}
            onClick={() => {
              handleFinish();
            }}
          >
            Finalizar
          </Button>
        </div>
      </div>
      {/* <div>
        <svg className="backButton" onClick={() => handleBack()} width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M36.4378 26.5005C36.4378 26.6151 36.3923 26.725 36.3113 26.806C36.2303 26.887 36.1204 26.9326 36.0058 26.9326H18.0383L25.0778 33.972C25.1588 34.0531 25.2044 34.163 25.2044 34.2777C25.2044 34.3923 25.1588 34.5023 25.0778 34.5834C24.9967 34.6644 24.8867 34.71 24.7721 34.71C24.6574 34.71 24.5475 34.6644 24.4664 34.5834L16.6892 26.8062C16.649 26.7661 16.6172 26.7184 16.5954 26.666C16.5737 26.6135 16.5625 26.5573 16.5625 26.5005C16.5625 26.4437 16.5737 26.3875 16.5954 26.335C16.6172 26.2826 16.649 26.2349 16.6892 26.1948L24.4664 18.4176C24.5065 18.3775 24.5542 18.3456 24.6066 18.3239C24.6591 18.3022 24.7153 18.291 24.7721 18.291C24.8289 18.291 24.8851 18.3022 24.9375 18.3239C24.99 18.3456 25.0376 18.3775 25.0778 18.4176C25.1179 18.4578 25.1498 18.5054 25.1715 18.5579C25.1932 18.6103 25.2044 18.6665 25.2044 18.7233C25.2044 18.7801 25.1932 18.8363 25.1715 18.8888C25.1498 18.9412 25.1179 18.9889 25.0778 19.029L18.0383 26.0684H36.0058C36.1204 26.0684 36.2303 26.114 36.3113 26.195C36.3923 26.276 36.4378 26.3859 36.4378 26.5005Z"
            fill="#333F64"
            stroke="#333F64"
            strokeWidth="1.15217"
          />
          <circle cx="26.5" cy="26.5" r="25.8205" stroke="#333F64" strokeWidth="1.35897" />
        </svg>
      </div> */}
    </div>
  );
};
