/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, useContext, useEffect, useState } from "react";
import { Avatar, Button, Popover } from "antd";
import { CustomerContext, UserContext } from "src/config/userContext";
import { logout } from "src/config/firebase";
import { useAppDispatch } from "src/redux/hooks";
import { useNavigate } from "react-router-dom";
import { setCall, setCallsArr } from "src/redux/slices/calls-ui";
import logoRuedita from "@doar/shared/images/logo/logo-ruedita-fondo-negro.png";

interface ProfileImageProps {
  collapsed?: boolean;
}

const ProfileImage: FC<ProfileImageProps> = ({ collapsed }) => {
  const { user, userUpdate } = useContext(UserContext);
  const { customer, customerUpdate } = useContext(CustomerContext);
  const [showAdminBadge, setShowAdminBadge] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.user?.role === "ADMIN") {
      setShowAdminBadge(true);
    } else {
      setShowAdminBadge(false);
    }
  }, [user]);

  const handleLogout = (e: any) => {
    e.preventDefault();
    logout();
    userUpdate({
      auth_uid: "defaultUid",
      email: "default@email.com",
      id: "defaultId",
      name: "defaultName",
      permissions: [],
      phone: {},
      role: "CLIENT",
      sip_settings: {},
    });
    customerUpdate({
      id: "defaultCustomer",
      name: "defaultName",
      accounts: [{}],
    });
    dispatch(setCallsArr([]));
    dispatch(setCall({ payload: {}, type: "" }));
    sessionStorage.removeItem("customerSelected");
    setPopoverVisible(false); // Cierra el popover al hacer clic en Logout
  };

  const handlePreferences = () => {
    navigate("/preferences");
    setPopoverVisible(false); // Cierra el popover al hacer clic en Preferencias
  };

  const menuContent = (
    <div>
      <div onClick={handlePreferences} style={{ cursor: "pointer", padding: "5px 0" }}>
        Preferencias
      </div>
      <div onClick={handleLogout} style={{ cursor: "pointer", padding: "5px 0" }}>
        Logout
      </div>
    </div>
  );

  return (
    <div style={{ marginTop: "8px" }}>
      <Popover content={menuContent} trigger="hover" open={popoverVisible} onOpenChange={(visible) => setPopoverVisible(visible)}>
        <div style={{ display: "flex", cursor: "pointer", alignItems: "center" }}>
          <Avatar src={logoRuedita} />
          {!collapsed && user?.user?.name && <div style={{ marginLeft: "10px", color: "white" }}>{user?.user?.name}</div>}
        </div>
      </Popover>
    </div>
  );
};

export default ProfileImage;
